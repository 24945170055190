import * as React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Theme, Typography } from '@material-ui/core'
import { LinkProps, StartonLink } from 'components/Core/StartonLink/StartonLink'
import { NAV_HEIGHT } from 'components/Core/StartonNav/StartonNav'

/*
|--------------------------------------------------------------------------
| CONTRACTS
|--------------------------------------------------------------------------
*/
export type IStartonNavLinkProps = LinkProps
type StyleProps = Record<string, string>
type StyleClassKey = 'navLinkContainer' | 'navLinkText'
type PropClasses = Record<StyleClassKey, string>

/*
|--------------------------------------------------------------------------
| STYLES
|--------------------------------------------------------------------------
*/
const useStyles = makeStyles<Theme, StyleProps, StyleClassKey>((theme) => ({
	navLinkContainer: {
		color: theme.palette.text.primary,
		height: NAV_HEIGHT,
		padding: '0px 12px',
		textTransform: 'uppercase',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		'&:hover, &:focus': {
			color: theme.palette.primary.main,
		},
	},
	navLinkText: {
		fontWeight: 'bold',
		fontSize: '0.9rem',
		textDecoration: 'none',
	},
}))

/*
|--------------------------------------------------------------------------
| COMPONENT
|--------------------------------------------------------------------------
*/
const StartonNavLink: React.FC<IStartonNavLinkProps> = (props: IStartonNavLinkProps) => {
	const classes: PropClasses = useStyles({} as StyleProps)
	const { children, ...restProps } = props

	return (
		<StartonLink className={classes.navLinkContainer} {...restProps}>
			<Typography className={classes.navLinkText}>{children}</Typography>
		</StartonLink>
	)
}

/*
|--------------------------------------------------------------------------
| EXPORT
|--------------------------------------------------------------------------
*/
export { StartonNavLink }
