/*
| Developed by Starton
|
| Author : Cedric Cervantes (cedric@starton.io)
*/

import * as React from 'react'
import { Box, Button, Grid, IconButton, InputAdornment, Theme, Tooltip, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import * as Yup from 'yup'
import { Field, Form, Formik } from 'formik'
import { FormikHelpers } from 'formik/dist/types'
import { TextField } from 'formik-material-ui'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { Alert, AlertTitle } from '@material-ui/lab'
import { ethers } from 'ethers'
import HelpIcon from '@material-ui/icons/Help'
import { useWeb3React } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'
import { StartonButton, StartonWeb3Provider } from 'components/Core'
import starton from 'data/axios'
import { WalletEntity } from 'model/walletEntity'
import { useUser } from 'stores/user/selectors'
import {
	injected,
	NETWORK_NAMES,
	portis,
	torus,
	walletconnect,
	walletlink,
} from 'components/Core/StartonCurrencyModal/deposit/Web3Connectors'
import { CurrencyEntity } from 'model/currencyEntity'
import { useSnack } from 'stores/settings/selectors'

/*
|--------------------------------------------------------------------------
| INTERFACES
|--------------------------------------------------------------------------
*/
interface IForm {
	amount: number
}
export interface ISAMDepositEthereumProps {
	currency: CurrencyEntity
	handleCloseModal: () => void
}
type StyleProps = ISAMDepositEthereumProps
type StyleClassKey = 'modalAction' | 'maxAmount' | 'buttonHelp' | 'modalItem'
type PropClasses = Record<StyleClassKey, string>

const useStyles = makeStyles<Theme, StyleProps, StyleClassKey>((theme) => ({
	modalItem: {
		'&:hover, &:focus': {
			backgroundColor: '#1a202c0a',
			cursor: 'pointer',
		},
	},
	modalAction: {
		width: '100%',
		// padding: theme.spacing(3, 4, 0),
	},
	maxAmount: {
		position: 'absolute',
		right: 0,
		top: -17,
		color: theme.palette.primary.main,
		cursor: 'pointer',
	},
	buttonHelp: {
		color: theme.palette.text.hint,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		bottom: 5,
	},
}))

enum ConnectorNames {
	Injected = 'Injected',
	WalletConnect = 'WalletConnect',
	WalletLink = 'WalletLink',
	Portis = 'Portis',
	Torus = 'Torus',
}

const SAMDepositEthereum: React.FC<ISAMDepositEthereumProps> = (props: ISAMDepositEthereumProps) => {
	const classes: PropClasses = useStyles({} as StyleProps)
	const { t } = useTranslation()
	const [verified, setVerified] = React.useState<boolean>(false)
	const [balance, setBalance] = React.useState<number>(0)
	const { user } = useUser()
	const context = useWeb3React<Web3Provider>()
	const { enqueueSnack } = useSnack()
	const { connector, library, chainId, account, activate, deactivate, error } = context
	const connectorsByName: { [connectorName in ConnectorNames]: any } = {
		[ConnectorNames.Injected]: injected,
		[ConnectorNames.WalletConnect]: walletconnect(
			props.currency.blockchainData.chainId,
			props.currency.blockchainData.rpcUrl,
		),
		[ConnectorNames.WalletLink]: walletlink(
			props.currency.blockchainData.chainId,
			props.currency.blockchainData.rpcUrl,
		),
		[ConnectorNames.Portis]: portis,
		[ConnectorNames.Torus]: torus,
	}

	const disconnect = () => {
		deactivate()
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		if (connector && connector.close) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			connector.close()
		}
	}

	// handle logic to recognize the connector currently being activated
	const [activatingConnector, setActivatingConnector] = React.useState<any>()
	React.useEffect(() => {
		if (activatingConnector && activatingConnector === connector) {
			setActivatingConnector(undefined)
		}
	}, [activatingConnector, connector])

	// handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already
	// const triedEager = useEagerConnect()

	// handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
	// useInactiveListener(!triedEager || !!activatingConnector)

	const schemaValidations = React.useMemo(() => {
		return Yup.object().shape({
			amount: Yup.number().required().max(balance, 'Your balance is too low'),
		})
	}, [balance])

	React.useEffect(() => {
		console.log('chainId', chainId)
	}, [chainId])

	React.useEffect(() => {
		console.log('error', error)
	}, [error])

	/**
	 * When the account change, fetch the balance
	 */
	React.useEffect(() => {
		const run = async () => {
			if (props.currency.type === CurrencyEntity.TypeEnum.Erc20) {
				try {
					const contract = new ethers.Contract(
						props.currency.blockchainAddress,
						props.currency.blockchainData.abi,
						library?.getSigner(),
					)
					let balance = await contract.balanceOf(account)
					balance = ethers.utils.formatUnits(balance, props.currency.decimal)
					setBalance(balance)
				} catch (e) {
					console.log(e)
				}
			} else {
				await library?.getBalance(account as string).then((balance) => {
					const amount = ethers.utils.formatUnits(balance, props.currency.decimal)
					setBalance(amount)
				})

				// const feeData = await provider.getFeeData()
				// const fees = feeData.gasPrice?.mul(21000)
				// console.log("fees", fees.toString())
				// console.log("fees", ethers.utils.formatUnits(fees, props.currency.decimal).toString())
				// console.log(wallet.balance)
				// console.log(amount)
			}
		}
		run()
	}, [account, chainId])

	/**
	 * When account change, check if this account is already verified
	 */
	React.useEffect(() => {
		starton
			.get(`/wallet?owner=${user?.id}&address=${account}&isVerified=true`)
			.then((response) => {
				if (response.data.meta.totalItems > 0) {
					setVerified(true)
				} else {
					setVerified(false)
				}
			})
			.catch(() => {
				setVerified(false)
			})
	}, [account])

	const handleSubmit = async (values: IForm, formikHelpers: FormikHelpers<IForm>) => {
		formikHelpers.setSubmitting(true)
		try {
			if (props.currency.type === CurrencyEntity.TypeEnum.Crypto) {
				await sendNativeToken(values.amount.toString())
			} else {
				await sendErc20(values.amount.toString())
			}
			enqueueSnack({
				message: t('wallet.ethereum.pending_deposit'),
				options: {
					variant: 'success',
				},
			})
			props.handleCloseModal()
		} catch (e) {
			console.log(e)
			enqueueSnack({
				message: t('wallet.ethereum.error_deposit'),
				options: {
					variant: 'error',
				},
			})
		}

		formikHelpers.setSubmitting(false)
	}

	const verifyWallet = async () => {
		/**
		 * Fix invalid signature
		 * Use personal_sign instead of eth_sign
		 * https://github.com/walletlink/walletlink/issues/45
		 * */
		Object.defineProperties(library?.provider, { isMetaMask: { value: true } })

		await library
			?.getSigner()
			.signMessage(user?.id as string)
			.then((signature) => {
				starton
					.post('/wallet', {
						owner: user?.id,
						type: WalletEntity.TypeEnum.External,
						address: account,
						signature: signature,
					})
					.then((response) => {
						setVerified(true)
						console.log(response)
					})
			})
	}

	const sendErc20 = async (amount: string) => {
		const amountInGwei = ethers.utils.parseUnits(amount, props.currency.decimal)
		const contract = new ethers.Contract(
			props.currency.blockchainAddress,
			props.currency.blockchainData.abi,
			library?.getSigner(),
		)
		await contract.transfer(props.currency.blockchainData.depositAddress, amountInGwei)
	}

	const sendNativeToken = async (amount: string) => {
		await library?.getSigner().sendTransaction({
			to: props.currency.blockchainData.depositAddress,
			from: account as string,
			value: ethers.utils.parseUnits(amount, props.currency.decimal).toHexString(),
		})
	}

	// RENDER
	// ----------------------------------------------------------------------------
	return (
		<React.Fragment>
			{connector ? (
				<Box>
					{chainId !== props.currency.blockchainData.chainId ? (
						<Alert
							severity="warning"
							action={
								<Button color="inherit" size="small" onClick={() => disconnect()}>
									Disconnect
								</Button>
							}
						>
							Wrong network, please switch to {NETWORK_NAMES[props.currency.blockchainData.chainId]} and
							try again
						</Alert>
					) : (
						<Box>
							{verified ? (
								<Box>
									<Alert
										className="mb-10"
										action={
											<Button color="inherit" size="small" onClick={() => disconnect()}>
												Disconnect
											</Button>
										}
									>
										<AlertTitle>Connected</AlertTitle>
									</Alert>
									<Formik
										initialValues={{
											amount: '',
										}}
										onSubmit={handleSubmit}
										validationSchema={schemaValidations}
									>
										{(formikProps) => (
											<Form>
												<Box className="relative">
													<Typography
														onClick={() => formikProps.setFieldValue('amount', balance)}
														variant="caption"
														className={classes.maxAmount}
													>
														{t('withdraw.max_amount')}
													</Typography>
													<Field
														component={TextField}
														id="amount"
														type="number"
														name="amount"
														label={t('wallet.amount')}
														variant="outlined"
														placeholder={t('wallet.enter_amount')}
														InputLabelProps={{
															shrink: true,
														}}
														InputProps={{
															endAdornment: (
																<InputAdornment position="end">
																	{props.currency.symbol}
																</InputAdornment>
															),
															type: 'number',
														}}
														fullWidth
													/>
												</Box>
												<Box className="flex flex-row justify-between items-center mt-1">
													<Typography
														variant="caption"
														className={classes.buttonHelp}
													></Typography>
													<Typography variant="caption" className={classes.buttonHelp}>
														<span>
															{t('Available')}{' '}
															<strong>
																{balance} {props.currency.symbol}
															</strong>
														</span>
														<Tooltip title={t('available') as string} arrow>
															<IconButton
																size="small"
																aria-label={t('tools.more_informations')}
															>
																<HelpIcon fontSize="small" />
															</IconButton>
														</Tooltip>
													</Typography>
												</Box>

												<Box className="flex flex-col md:flex-row md:justify-between items-center">
													<StartonButton
														fullWidth
														type="submit"
														className={clsx(
															classes.modalAction,
															'order-1 md:order-2 md:ml-2 mt-5',
														)}
														disabled={!formikProps.isValid}
														loading={formikProps.isSubmitting}
													>
														{t('wallet.deposit')} {formikProps.values.amount}{' '}
														{props.currency.symbol}
													</StartonButton>
												</Box>
											</Form>
										)}
									</Formik>
								</Box>
							) : (
								<Box>
									<Alert
										severity="warning"
										className="mb-10"
										action={
											<Button color="inherit" size="small" onClick={() => disconnect()}>
												Disconnect
											</Button>
										}
									>
										<AlertTitle>Connected - Signature required</AlertTitle>
									</Alert>
									<StartonButton
										fullWidth
										className={clsx(classes.modalAction, 'order-1 md:order-2 md:ml-2 mt-5')}
										onClick={verifyWallet}
									>
										{t('wallet.sign')}
									</StartonButton>
								</Box>
							)}
						</Box>
					)}
				</Box>
			) : (
				<Grid container spacing={1} justifyContent="center" alignItems="center">
					<Grid item xs={6}>
						<StartonWeb3Provider
							iconWidth={50}
							image="/images/metamask.svg"
							name="MetaMask"
							description={t('wallet.startonWeb3Provider.descriptionMetaMask')}
							onClick={() => {
								setActivatingConnector(connectorsByName['Injected'])
								activate(connectorsByName['Injected'])
							}}
						/>
					</Grid>
					<Grid item xs={6}>
						<StartonWeb3Provider
							iconWidth={50}
							image="/images/walletLink.svg"
							name="Wallet Link"
							description={t('wallet.startonWeb3Provider.descriptionWalletLink')}
							onClick={() => {
								setActivatingConnector(connectorsByName['WalletLink'])
								activate(connectorsByName['WalletLink'])
							}}
						/>
					</Grid>
					<Grid item xs={6}>
						<StartonWeb3Provider
							iconWidth={40}
							image="/images/portis.svg"
							name="Portis"
							description={t('wallet.startonWeb3Provider.descriptionPortis')}
							onClick={() => {
								setActivatingConnector(connectorsByName['Portis'])
								activate(connectorsByName['Portis'])
							}}
						/>
					</Grid>
					<Grid item xs={6}>
						<StartonWeb3Provider
							iconWidth={65}
							image="/images/walletConnect.svg"
							name="WalletConnect"
							description={t('wallet.startonWeb3Provider.descriptionWalletConnect')}
							onClick={() => {
								setActivatingConnector(connectorsByName['WalletConnect'])
								activate(connectorsByName['WalletConnect'])
							}}
						/>
					</Grid>
					{/*<StartonWeb3Provider onClick={() => wallet.connect('torus')}>torus</StartonButton> */}
				</Grid>
			)}
		</React.Fragment>
	)
}

/*
|--------------------------------------------------------------------------
| EXPORT
|--------------------------------------------------------------------------
*/
export { SAMDepositEthereum }
