/*
|--------------------------------------------------------------------------
| INTERFACES
|--------------------------------------------------------------------------
*/
export interface StripeConfig {
	currency: string
	// Set your amount limits: Use float for decimal currencies and
	// Integer for zero-decimal currencies: https://stripe.com/docs/currencies#zero-decimal.
	minAmount: number
	maxAmount: number
	amountStep: number
}

/*
|--------------------------------------------------------------------------
| DEFAULT CONFIG
|--------------------------------------------------------------------------
*/
export const DEFAULT_WALLET_FORM_CONFIG: StripeConfig = {
	currency: 'eur',
	minAmount: 10.0,
	maxAmount: 5000.0,
	amountStep: 5.0,
}
