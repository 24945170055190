import * as React from 'react'
import { useDispatch } from 'react-redux'
import { SnackbarKey, useSnackbar } from 'notistack'
import { useSettings } from 'stores/settings/selectors'
import { removeSnackbar } from 'stores/settings'

/*
|--------------------------------------------------------------------------
| CACHED DISPLAYED
|--------------------------------------------------------------------------
*/
let displayed: Array<SnackbarKey> = []

/*
|--------------------------------------------------------------------------
| COMPONENT
|--------------------------------------------------------------------------
*/
const StartonNotifier: React.FC = () => {
	const dispatch = useDispatch()
	const snackbars = useSettings().snackbars
	const { enqueueSnackbar, closeSnackbar } = useSnackbar()

	// Methods
	// ----------------------------------------------------------------------------
	const storeDisplayed = (id: SnackbarKey) => {
		displayed = [...displayed, id]
	}
	const removeDisplayed = (id: SnackbarKey) => {
		displayed = [...displayed.filter((key) => id !== key)]
	}

	// Effect for hydrate snackbar
	// ----------------------------------------------------------------------------
	React.useEffect(() => {
		snackbars.forEach(({ key, message, options = {}, dismissed = false }) => {
			if (dismissed) {
				// dismiss snackbar using notistack
				closeSnackbar(key)
				return
			}

			// do nothing if snackbar is already displayed
			if (key && displayed.includes(key)) return

			// display snackbar using notistack
			enqueueSnackbar(message, {
				key,
				...options,
				onClose: (event, reason, myKey) => {
					if (options.onClose) {
						options.onClose(event, reason, myKey)
					}
				},
				onExited: (_event, myKey) => {
					// remove this snackbar from redux store
					dispatch(removeSnackbar(myKey))
					removeDisplayed(myKey)
				},
			})

			// keep track of snackbars that we've displayed
			key && storeDisplayed(key)
		})
	}, [snackbars, closeSnackbar, enqueueSnackbar, dispatch])

	return null
}

/*
|--------------------------------------------------------------------------
| EXPORT
|--------------------------------------------------------------------------
*/
export { StartonNotifier }
