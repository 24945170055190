import * as React from 'react'
import { Box } from '@material-ui/core'

/*
|--------------------------------------------------------------------------
| INTERFACES
|--------------------------------------------------------------------------
*/
export interface IStartonTabPanelProps
	extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
	children?: React.ReactNode
	index: number | string
	value: number | string
}

/*
|--------------------------------------------------------------------------
| COMPONENT
|--------------------------------------------------------------------------
*/
const StartonTabPanel: React.FC<IStartonTabPanelProps> = ({
	children,
	value,
	index,
	...restProps
}: IStartonTabPanelProps) => {
	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`tabpanel-${index}`}
			aria-labelledby={`tab-${index}`}
			{...restProps}
		>
			{value === index && <Box id={`tabcontent-${index}`}>{children}</Box>}
		</div>
	)
}

/*
|--------------------------------------------------------------------------
| EXPORT
|--------------------------------------------------------------------------
*/
export { StartonTabPanel }
