import * as React from 'react'
// import { Theme, useTheme } from '@material-ui/core'
import { ISVGProps } from 'components/SVG/contracts'

/*
|--------------------------------------------------------------------------
| CONTRACTS
|--------------------------------------------------------------------------
*/
type IPassportProps = ISVGProps

/*
|--------------------------------------------------------------------------
| COMPONENT
|--------------------------------------------------------------------------
*/
const Passport: React.FC<IPassportProps> = (props: IPassportProps) => {
	// const theme: Theme = useTheme()
	// const fill: string = React.useMemo<string>(() => props.fill || theme.palette.text.primary, [props])

	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 469.168 469.168" {...props}>
			<path
				d="M241.127 8.008c-10.592 0-20.328 7.088-23.184 17.792l-95.248 355.456c-3.424 12.784 4.184 25.968 16.968 29.4l185.472 49.696c12.672 3.416 25.968-4.192 29.376-16.976l95.24-355.464c3.424-12.784-4.184-25.968-16.952-29.392L247.319 8.824a23.95 23.95 0 00-6.192-.816z"
				fill="#f5f9ec"
			/>
			<path
				d="M414.151 220.776L291.327 97.952h-92.72l-75.912 283.304c-3.424 12.784 4.184 25.968 16.968 29.4l185.472 49.696c12.672 3.416 25.968-4.192 29.376-16.976l59.64-222.6z"
				fill="#e4e8db"
			/>
			<path
				d="M176.527 180.344l231.816 62.112 41.416-154.552c3.424-12.784-4.184-25.968-16.952-29.392L247.319 8.824a23.934 23.934 0 00-6.184-.816c-10.592 0-20.328 7.088-23.184 17.792l-41.424 154.544z"
				fill="#dcc69f"
			/>
			<path fill="#cdb994" d="M176.527 180.344l231.816 62.112 5.808-21.68L291.327 97.952h-92.72z" />
			<path
				d="M155.119 414.792L262.783 12.968l-15.472-4.144a23.934 23.934 0 00-6.184-.816c-10.592 0-20.328 7.088-23.184 17.792l-95.248 355.456c-3.424 12.784 4.184 25.968 16.968 29.4l15.456 4.136z"
				fill="#ea672c"
			/>
			<path
				d="M26.583 430.152c-4.408 0-8-3.584-8-8v-320c0-4.416 3.592-8 8-8h240c13.232 0 24 10.768 24 24v288c0 13.232-10.768 24-24 24h-240z"
				fill="#125d9d"
			/>
			<path
				d="M154.583 134.152c-53.016 0-96 42.984-96 96s42.984 96 96 96 96-42.984 96-96-42.976-96-96-96zm71.048 60h-34.6c-3.312-16.584-8.48-29.984-13.752-40.32a79.872 79.872 0 0148.352 40.32zm-47.048 36c0 7.144-.536 13.704-1.272 20h-45.456c-.736-6.296-1.272-12.856-1.272-20s.528-13.704 1.256-20h45.488c.728 6.296 1.256 12.856 1.256 20zm-44.072-36c4.44-20.048 11.768-34.616 17.744-43.768.792-.024 1.528-.232 2.328-.232.808 0 1.552.216 2.352.24 6 9.208 13.304 23.784 17.728 43.76h-40.152zm-2.624-40.32c-5.256 10.32-10.432 23.736-13.744 40.32H83.535a79.872 79.872 0 0148.352-40.32zm-54.464 56.32h38.296c-.672 6.336-1.136 12.928-1.136 20 0 7.08.48 13.664 1.16 20h-38.32c-1.672-6.432-2.84-13.056-2.84-20s1.168-13.568 2.84-20zm6.112 56h34.648c3.344 16.616 8.52 30.136 13.68 40.312-21.128-6.312-38.472-20.968-48.328-40.312zm68.72 43.768c-5.736-8.824-13.2-23.424-17.728-43.768h40.112c-4.52 20.328-11.976 34.928-17.728 43.768-.792.024-1.528.232-2.328.232s-1.536-.208-2.328-.232zm25.056-3.456c5.168-10.192 10.336-23.696 13.672-40.312h34.648c-9.856 19.344-27.192 34-48.32 40.312zm54.432-56.312h-38.312c.672-6.336 1.152-12.92 1.152-20 0-7.072-.464-13.664-1.128-20h38.288c1.672 6.432 2.84 13.056 2.84 20s-1.168 13.568-2.84 20z"
				fill="#e7be29"
			/>
			<path fill="#fff" d="M74.583 374.152h160v16h-160z" />
			<g fill="#010101">
				<path d="M361.427 263.025l-8.284 30.908-15.455-4.142 8.285-30.909zM344.865 324.795l-28.984 108.178-15.455-4.141 28.985-108.178z" />
				<path d="M434.855 50.792L249.399 1.096A32.02 32.02 0 00241.095 0c-14.128 0-27.056 9.432-30.888 23.728l-16.728 62.424H26.583c-8.832 0-16 7.16-16 16v320c0 8.84 7.168 16 16 16h184.8l111.664 29.92a32.02 32.02 0 008.304 1.096c14.128 0 27.056-9.432 30.888-23.728l95.248-355.464c4.576-17.064-5.56-34.608-22.632-39.184zm-408.272 51.36h240c8.824 0 16 7.176 16 16v288c0 8.824-7.176 16-16 16h-240v-320zM442.031 85.84l-95.248 355.464c-1.872 6.992-8.224 11.872-15.44 11.872-1.392 0-2.792-.184-4.16-.552l-55.792-14.952c15.376-2.344 27.192-15.496 27.192-31.52v-126.84l8.192 2.2 8.288-30.912-15.456-4.144-1.024 3.824V123.128l16.328 4.376 18.92 61.864 13.248 3.552-1.256-57.128c8.696 2.336 17.584 4.712 23.4 6.272l10.288 18.136 8.832 2.368-1.52-25.256 13.944-21.112-8.832-2.368-17.976 10.568c-5.824-1.56-14.704-3.936-23.408-6.272l29.648-48.848-13.248-3.552-47.32 44.112c-9.304-2.496-19.456-5.216-24.96-6.688-5.376-10.064-15.872-17-28.088-17h-56.536l15.616-58.288c1.872-6.984 8.224-11.864 15.44-11.872 1.392 0 2.792.184 4.16.552L430.719 66.24a15.87 15.87 0 019.712 7.456c2.136 3.712 2.704 8.016 1.6 12.144z" />
				<path d="M357.019 220.605l-4.093 15.467-15.204-4.023 4.092-15.467zM387.421 228.656l-4.092 15.467-15.205-4.023 4.093-15.467zM326.638 212.592l-4.093 15.468-15.204-4.024 4.093-15.467z" />
			</g>
		</svg>
	)
}

/*
|--------------------------------------------------------------------------
| EXPORT
|--------------------------------------------------------------------------
*/
const PassportSVG = React.memo(Passport)
export { PassportSVG }
