import * as React from 'react'
import { Box, Card, CardProps, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import VisibilityIcon from '@material-ui/icons/Visibility'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import BigNumber from 'bignumber.js'
import organisation from '../../../../.tmp/organisation.json'
import platform from '../../../../.tmp/platform'
import { StartonCurrencySVG } from './StartonCurrencySVG'
import { DiruptUtils } from '@dirupt/utils'
import { StartonCurrencyModal, StartonLink } from 'components/Core'
import { CurrencyEntity } from 'model/currencyEntity'

/*
|--------------------------------------------------------------------------
| CONTRACTS
|--------------------------------------------------------------------------
*/
export interface IStartonCurrencyGradient {
	startColor: string
	endColor: string
}

export interface IStartonWallet {
	currency: any
	balance: string
	owner?: string
}

export interface IStartonCurrencyCardActions {
	deposit: boolean
	withdraw: boolean
	buy: boolean
	transfer: boolean
}
export interface IStartonCurrencyCardProps {
	currency: CurrencyEntity
	balance: string
	cardProps?: CardProps
}
type StyleProps = IStartonCurrencyCardProps
type StyleClassKey =
	| 'cardContainer'
	| 'svg'
	| 'contentContainer'
	| 'contentWrapper'
	| 'symbol'
	| 'symbolText'
	| 'eyeIcon'
	| 'priceContainer'
	| 'priceTitle'
	| 'priceValue'
	| 'priceValueDark'
	| 'walletTitle'
	| 'walletValue'
	| 'actionContainer'
	| 'actionWrapper'
	| 'priceTitleAmount'
	| 'startonLink'
type PropClasses = Record<StyleClassKey, string>

/*
|--------------------------------------------------------------------------
| CONSTANTS
|--------------------------------------------------------------------------
*/
const CARD_MARGIN = 50

/*
|--------------------------------------------------------------------------
| STYLES
|--------------------------------------------------------------------------
*/
const useStyles = makeStyles<Theme, StyleProps, StyleClassKey>((theme) => ({
	cardContainer: {
		position: 'relative',
		background: `#FFFFFF 0% 0% no-repeat padding-box`,
		boxShadow: (props: StyleProps) =>
			`0px 12px 32px ${DiruptUtils.convertHexToRGVA(
				props.currency.frontCard.gradientColor.startColor as string,
				0.15,
			)}, inset rgba(0,0,0,0.03) 0px 0px 10px`,
		borderRadius: 20,
		height: 'auto',
		maxWidth: 415,
		width: `100%`,
		marginBottom: CARD_MARGIN,
		transition: 'all 0.25s cubic-bezier(.17,.67,.83,.67)',
		'&:hover, &:focus': {
			transform: 'scale(1.02)',
		},
		[theme.breakpoints.up('md')]: {
			width: `calc(${100 / 2}% - ${CARD_MARGIN}px)`,
		},
		[theme.breakpoints.up('lg')]: {
			width: `calc(${100 / 3}% - ${CARD_MARGIN}px)`,
		},
	},
	svg: {
		position: 'absolute',
		top: -4,
		left: 0,
		right: 0,
		width: '100%',
		height: 'auto',
		[theme.breakpoints.up('sm')]: {
			height: 235,
		},
	},
	startonLink: {
		[theme.breakpoints.between('lg', 1380)]: {
			paddingTop: '10px',
		},
	},
	contentContainer: {
		width: '100%',
		position: 'relative',
		minHeight: 205,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		[theme.breakpoints.up('sm')]: {
			minHeight: 235,
		},
	},
	contentWrapper: {
		padding: theme.spacing(1, 2),
	},
	symbol: {
		width: 28,
		height: 28,
		backgroundSize: 'cover',
		marginRight: theme.spacing(1),
		[theme.breakpoints.up('sm')]: {
			width: 38,
			height: 38,
		},
	},
	symbolText: {
		color: 'white',
		alignSelf: 'center',
		fontWeight: 600,
		fontSize: theme.typography.pxToRem(14),
		textTransform: 'uppercase',
	},
	eyeIcon: {
		display: 'none',
		[theme.breakpoints.up('sm')]: {
			display: 'block',
			color: 'white',
			fontSize: theme.typography.pxToRem(25),
			marginBottom: theme.spacing(1),
		},
	},
	priceContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginTop: 14,
		[theme.breakpoints.up('sm')]: {
			marginTop: 29,
		},
	},
	priceTitle: {
		fontSize: theme.typography.pxToRem(12),
		fontWeight: 500,
		lineHeight: 1,
		textTransform: 'uppercase',
		color: theme.palette.text.primary,
	},
	priceTitleAmount: {
		fontSize: theme.typography.pxToRem(12),
		fontWeight: 500,
		lineHeight: 1,
		textTransform: 'uppercase',
		color: '000000',
	},
	priceValue: {
		fontSize: theme.typography.pxToRem(14),
		fontWeight: 700,
		color: theme.palette.text.primary,
	},
	priceValueDark: {
		fontSize: theme.typography.pxToRem(14),
		fontWeight: 700,
		color: '#000000',
	},
	walletTitle: {
		fontSize: theme.typography.pxToRem(12),
		fontWeight: 500,
		lineHeight: 1,
		textTransform: 'uppercase',
		opacity: 0.5,
		marginBottom: theme.spacing(1),
		color: theme.palette.text.primary,
	},
	walletValue: {
		fontSize: theme.typography.pxToRem(11),
		fontWeight: 500,
		lineHeight: 1,
		color: (props: StyleProps) => props.currency.frontCard.gradientColor.startColor,
		[theme.breakpoints.up('sm')]: {
			fontSize: theme.typography.pxToRem(14),
		},
	},
	actionContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
	},
	actionWrapper: {
		flex: 1,
		padding: theme.spacing(1, 0),
		borderTop: `2px solid ${theme.palette.background.paper}`,
		fontWeight: 500,
		color: '#1A202C',
		'&:first-child': {
			borderRight: (props: StyleProps) =>
				Object.values(props.currency?.frontCard.actions).filter(Boolean).length > 1
					? `2px solid ${theme.palette.background.paper}`
					: 'none',
		},
		'&:last-child': {
			borderLeft: (props: StyleProps) =>
				Object.values(props.currency?.frontCard.actions).filter(Boolean).length > 2
					? `2px solid ${theme.palette.background.paper}`
					: 'none',
		},
		'&:hover, &:focus': {
			background: (props: StyleProps) =>
				`transparent linear-gradient(90deg, ${props.currency?.frontCard.gradientColor.startColor} 0%, ${props.currency?.frontCard.gradientColor.endColor} 100%) 0% 0% no-repeat padding-box`,
			color: 'white',
			cursor: 'pointer',
		},
	},
}))

/*
|--------------------------------------------------------------------------
| COMPONENT
|--------------------------------------------------------------------------
*/
const StartonCurrencyCard: React.FC<IStartonCurrencyCardProps> = (props: IStartonCurrencyCardProps) => {
	const classes: PropClasses = useStyles(props as StyleProps)
	const { t } = useTranslation()
	const settings = organisation.settings
	const [openModal, setOpenModal] = React.useState<false | 'deposit' | 'withdraw'>(false)

	// Render
	// ----------------------------------------------------------------------------
	return (
		<React.Fragment>
			<Card {...props.cardProps} classes={{ root: classes.cardContainer }}>
				<StartonCurrencySVG
					className={classes.svg}
					name={DiruptUtils.handleize(props.currency.name)}
					{...props.currency.frontCard.gradientColor}
				/>
				<Box className={classes.contentContainer}>
					<StartonLink
						href={`/wallets/currency/${props.currency.symbol}/details`}
						className={clsx(classes.startonLink, 'flex flex-col justify-between flex-1 pb-3')}
					>
						<Box className={classes.contentWrapper}>
							<Box className="flex flex-row justify-between items-center">
								<Box className="flex flex-row items-center">
									<Box>
										<div
											className={classes.symbol}
											style={{ backgroundImage: `url(${props.currency.image})` }}
										/>
									</Box>
									<Typography className={classes.symbolText}>{props.currency.name}</Typography>
								</Box>
								<VisibilityIcon className={classes.eyeIcon} />
							</Box>
							<Box className={classes.priceContainer}>
								<Box className="flex flex-col text-left">
									<Typography className={clsx('text-white', classes.priceTitle)}>
										{t('wallet.last_know_price')}
									</Typography>
									<Typography className={clsx('text-white', classes.priceValue)}>
										{props?.currency?.currentPrice
											? `${new BigNumber(props?.currency?.currentPrice).toString()} ${
													platform?.settings?.currency?.symbol
											  }`
											: t('tools.unknown')}
									</Typography>
								</Box>
								<Box className="flex flex-col text-right">
									<Typography className={classes.priceTitleAmount}>{t('wallet.balance')}</Typography>
									<Typography className={classes.priceValueDark}>
										{`${new BigNumber(props.balance)}`} {props.currency.symbol}
									</Typography>
								</Box>
							</Box>
						</Box>
					</StartonLink>
					<Box className={classes.actionContainer}>
						{props.currency.frontCard.actions.deposit && (
							<Box className={classes.actionWrapper} onClick={() => setOpenModal('deposit')}>
								{t('wallet.deposit')}
							</Box>
						)}
						{props.currency.frontCard.actions.withdraw && (
							<Box className={classes.actionWrapper} onClick={() => setOpenModal('withdraw')}>
								{t('wallet.withdraw')}
							</Box>
						)}
					</Box>
				</Box>
			</Card>
			<StartonCurrencyModal
				open={openModal ? true : false}
				currency={props.currency}
				balance={props.balance}
				action={openModal}
				handleCloseModal={() => setOpenModal(false)}
			/>
		</React.Fragment>
	)
}

/*
|--------------------------------------------------------------------------
| EXPORT
|--------------------------------------------------------------------------
*/
export { StartonCurrencyCard }
