import * as React from 'react'
import { Theme, useTheme } from '@material-ui/core'
import { ISVGProps } from 'components/SVG/contracts'

/*
|--------------------------------------------------------------------------
| CONTRACTS
|--------------------------------------------------------------------------
*/
type IUndrawSecurityProps = ISVGProps

/*
|--------------------------------------------------------------------------
| COMPONENT
|--------------------------------------------------------------------------
*/
const UndrawSecurity: React.FC<IUndrawSecurityProps> = (props: IUndrawSecurityProps) => {
	const theme: Theme = useTheme()
	const fill: string = React.useMemo<string>(() => props.fill || theme.palette.primary.main, [props])

	return (
		<svg width={859.399} height={767.784} viewBox="0 0 859.399 767.784" {...props}>
			<defs>
				<linearGradient
					id="prefix__a"
					x1={344.921}
					y1={369.397}
					x2={505.399}
					y2={369.397}
					gradientUnits="userSpaceOnUse"
				>
					<stop offset={0} stopColor="gray" stopOpacity={0.25} />
					<stop offset={0.535} stopColor="gray" stopOpacity={0.12} />
					<stop offset={1} stopColor="gray" stopOpacity={0.1} />
				</linearGradient>
			</defs>
			<title>{'Security on'}</title>
			<path
				d="M431.4 10.746l-269.703 165.97s0 342.315 269.702 529.032C701.101 519.03 701.101 176.717 701.101 176.717z"
				fill="#3f3d56"
			/>
			<path
				d="M433.01 10.746l-4.61 2.837V702.51c1.53 1.083 3.06 2.166 4.61 3.238C702.711 519.03 702.711 176.717 702.711 176.717z"
				fill={fill}
				opacity={0.04}
			/>
			<path
				fill="url(#prefix__a)"
				d="M366.106 347.068l50.843 46.606 63.028-90.924 25.422 21.185-88.45 112.109-72.028-80.502 21.185-8.474z"
				opacity={0.7}
			/>
			<path
				fill="#3ad29f"
				d="M378.106 349.868l41.243 37.806 58.428-85.924 20.622 17.185-79.05 103.109-58.428-65.302 17.185-6.874z"
			/>
			<path
				d="M165.29 47.956a3.675 3.675 0 01-2.048-4.44 1.766 1.766 0 00.08-.408 1.843 1.843 0 00-3.31-1.221 1.766 1.766 0 00-.204.361 3.675 3.675 0 01-4.441 2.048 1.766 1.766 0 00-.408-.08 1.843 1.843 0 00-1.221 3.31 1.766 1.766 0 00.362.204 3.675 3.675 0 012.047 4.441 1.766 1.766 0 00-.08.408 1.843 1.843 0 003.31 1.221 1.766 1.766 0 00.205-.362 3.675 3.675 0 014.44-2.047 1.766 1.766 0 00.408.08 1.843 1.843 0 001.221-3.31 1.766 1.766 0 00-.361-.205zM19.29 372.956a3.675 3.675 0 01-2.048-4.44 1.766 1.766 0 00.08-.408 1.843 1.843 0 00-3.31-1.221 1.766 1.766 0 00-.204.361 3.675 3.675 0 01-4.441 2.048 1.766 1.766 0 00-.408-.08 1.843 1.843 0 00-1.221 3.31 1.766 1.766 0 00.362.204 3.675 3.675 0 012.047 4.441 1.766 1.766 0 00-.08.408 1.843 1.843 0 003.31 1.221 1.766 1.766 0 00.205-.362 3.675 3.675 0 014.44-2.047 1.766 1.766 0 00.408.08 1.843 1.843 0 001.221-3.31 1.766 1.766 0 00-.361-.205z"
				fill="#4d8af0"
				opacity={0.5}
			/>
			<circle cx={748.399} cy={254} r={6} fill="#f55f44" opacity={0.5} />
			<circle cx={121.399} cy={683} r={6} fill="#f55f44" opacity={0.5} />
			<circle cx={850.399} cy={485} r={6} fill="#f55f44" opacity={0.5} />
			<path
				d="M704.29 76.956a3.675 3.675 0 01-2.048-4.44 1.766 1.766 0 00.08-.408 1.843 1.843 0 00-3.31-1.221 1.766 1.766 0 00-.204.361 3.675 3.675 0 01-4.441 2.048 1.766 1.766 0 00-.408-.08 1.843 1.843 0 00-1.221 3.31 1.766 1.766 0 00.362.204 3.675 3.675 0 012.047 4.441 1.766 1.766 0 00-.08.408 1.843 1.843 0 003.31 1.221 1.766 1.766 0 00.205-.362 3.675 3.675 0 014.44-2.047 1.766 1.766 0 00.408.08 1.843 1.843 0 001.221-3.31 1.766 1.766 0 00-.361-.205zM804.29 704.956a3.675 3.675 0 01-2.048-4.44 1.766 1.766 0 00.08-.408 1.843 1.843 0 00-3.31-1.221 1.766 1.766 0 00-.204.361 3.675 3.675 0 01-4.441 2.048 1.766 1.766 0 00-.408-.08 1.843 1.843 0 00-1.221 3.31 1.766 1.766 0 00.362.204 3.675 3.675 0 012.047 4.441 1.766 1.766 0 00-.08.408 1.843 1.843 0 003.31 1.221 1.766 1.766 0 00.205-.362 3.675 3.675 0 014.44-2.047 1.766 1.766 0 00.408.08 1.843 1.843 0 001.221-3.31 1.766 1.766 0 00-.361-.205zM12.29 182.956a3.675 3.675 0 01-2.048-4.44 1.766 1.766 0 00.08-.408 1.843 1.843 0 00-3.31-1.221 1.766 1.766 0 00-.204.361 3.675 3.675 0 01-4.441 2.048 1.766 1.766 0 00-.408-.08 1.843 1.843 0 00-1.221 3.31 1.766 1.766 0 00.362.204 3.675 3.675 0 012.047 4.441 1.766 1.766 0 00-.08.408 1.843 1.843 0 003.31 1.221 1.766 1.766 0 00.205-.362 3.675 3.675 0 014.44-2.047 1.766 1.766 0 00.408.08 1.843 1.843 0 001.221-3.31 1.766 1.766 0 00-.361-.205z"
				fill="#4d8af0"
				opacity={0.5}
			/>
			<circle cx={79.399} cy={284} r={6} fill="#47e6b1" opacity={0.5} />
			<circle cx={853.399} cy={332} r={6} fill="#47e6b1" opacity={0.5} />
			<g opacity={0.5} fill="#47e6b1">
				<path d="M583.399 0h3v17h-3z" />
				<path d="M593.4 7v3h-17V7z" />
			</g>
			<g opacity={0.5} fill="#47e6b1">
				<path d="M594.399 666h3v17h-3z" />
				<path d="M604.4 673v3h-17v-3z" />
			</g>
			<g opacity={0.5} fill="#47e6b1">
				<path d="M26.399 702h3v17h-3z" />
				<path d="M36.4 709v3h-17v-3z" />
			</g>
			<g opacity={0.5} fill="#47e6b1">
				<path d="M709.399 396h3v17h-3z" />
				<path d="M719.4 403v3h-17v-3z" />
			</g>
			<path
				fill="#47e6b1"
				opacity={0.5}
				d="M795.606 123.018l6.443 5.906 9.128-13.424 3.222 2.685-12.35 16.109-9.128-10.202 2.685-1.074z"
			/>
			<path fill={fill} d="M589.444 240.134l22.225-.862 1.108 28.575-22.225.862z" />
			<path d="M511.41 339.35s-30.78-14.705-30.287-2.005 31.025 21.056 31.025 21.056z" fill="#ffb9b9" />
			<path
				d="M528.296 446.81s-14.52 35.543-19.886 61.19-13.536 60.942-3.026 85.973 40.392 98.6 40.7 106.537-2.56 15.999 3.79 15.752 40.907-11.125 42.31-15.95-11.79-17.032-11.79-17.032l-32.577-102.082 33.99-66.505 24.516 99.215 4.802 123.828s23.567-7.273 26.988-1.046c0 0 10.559-14.72 11.9-21.13s-4.987-128.592-4.987-128.592 21.633-139.163 4.17-138.486-85.864-41.188-120.9-1.671z"
				fill={fill}
			/>
			<path
				d="M549.628 709.912s-16.108 35.603-17.634 37.253-12.023 17.955-4.086 17.647 26.68-8.984 40.476-22.238 31.984-36.22 30.335-37.745-12.946-5.858-12.946-5.858zM613.992 729.675s-.972 15.937-3.9 22.41-2.56 16 5.377 15.692 28.453-4.284 28.453-4.284 1.218-9.587-.43-11.113-8.677-18.742-5.687-23.628-23.813.923-23.813.923z"
				fill="#575a89"
			/>
			<path
				d="M625.735 212.492s16.922 26.373 23.519 32.477-11.962 19.543-11.962 19.543l-33.215 4.468s.418-30.225-1.293-33.339 22.951-23.149 22.951-23.149z"
				fill="#ffb9b9"
			/>
			<path
				d="M596.865 247s6.842 12.455 13.193 12.208 39.196-14.239 40.599-19.063 10.88 34.556 10.88 34.556l-80.372 141.441-19.605-13.55 9.5-83.044 7.8-44.82z"
				fill={fill}
			/>
			<path
				d="M653.621 373.584c-3.206 6.707-5.208 13.7-4.93 20.86.012.318.024.635.052.952.76 14.694 6.174 31.687 8.115 44.045 1.41 8.864 1.009 15.335-4.18 16.856-12.576 3.668 1.835 6.289-46.825 22.485s-57.152 2.216-57.275-.959 6.891-27.296 5.242-28.822-23.074 19.974-29.548 17.045-.738-19.05-.738-19.05 14.582-33.954 14.274-41.892 22.09-45.375 22.09-45.375l15.234-99.166s5.857-12.947 13.733-14.842 14.411 2.62 14.411 2.62l-13.18 29.13-13.905 51.418 2.326 18.989s16.17-34.016 26.79-47.147 33.265-44.218 33.265-44.218 5.286-17.838 1.295-23.168c-3.991-5.347 16.647-6.147 25.447 15.771 4.664 11.616 12.236 28.94 16.979 45.068 4.227 14.288 6.223 27.646 2.036 35.281-6.4 11.664-22.5 26.995-30.708 44.119z"
				fill="#575a89"
			/>
			<path
				d="M684.329 329.465c-6.4 11.664-22.5 26.995-30.708 44.119-2.144-7.723-4.76-15.715-4.76-15.715s-10.03-53.668 19.64-67.538a19.858 19.858 0 0113.792 3.853c4.227 14.289 6.223 27.646 2.036 35.281z"
				opacity={0.1}
			/>
			<path
				d="M566.543 326.083l-25.155 7.335-34.926 1.355-3.593 30.348 57.398 4.133s14.337-40.304 6.276-43.171z"
				fill="#575a89"
			/>
			<path d="M607.56 428.123s-33.651 5.583-25.97 15.71 37.492-.52 37.492-.52z" fill="#ffb9b9" />
			<path
				d="M648.743 395.396c.76 14.694 6.174 31.687 8.115 44.045-10.72 10.146-20.914 19.095-24.756 19.244-7.937.308-20.7-.787-25.4.985s-7.459-28.33-7.459-28.33 2.806-9.648 15.322-14.903c8.447-3.555 24.64-13.198 34.178-21.04z"
				opacity={0.1}
			/>
			<path
				d="M668.133 280.805s26.865-4.221 25.413 40.353 2.832 73.027-7.973 81.396-45.902 46.298-53.84 46.606-20.7-.788-25.4.985-7.46-28.33-7.46-28.33 2.807-9.648 15.322-14.903 42.002-23.888 41.756-30.238-7.458-28.33-7.458-28.33-10.031-53.669 19.64-67.539z"
				fill="#575a89"
			/>
			<circle cx={602.045} cy={216.591} r={34.952} fill="#ffb9b9" />
			<path
				d="M578.511 166.494a6.749 6.749 0 01-2.939 1.475 2.097 2.097 0 01-2.43-1.683c-.8 1.658-1.702 3.425-3.335 4.276s-4.157.138-4.392-1.688a7.706 7.706 0 01-1.774 3.744c-1.013.966-2.85 1.21-3.734.125.715 3.907.273 7.925.498 11.891s1.282 8.17 4.278 10.779c4.37 3.803 10.941 2.864 16.69 2.144a5.066 5.066 0 012.615.143c2.152.917 2.055 3.913 2.084 6.252a17.373 17.373 0 0015.212 16.498 8.709 8.709 0 006.472-1.58c1.632-1.383 2.466-3.638 4.336-4.677 3.042-1.69 6.732.775 8.956 3.452s4.416 5.969 7.862 6.459c4.556.648 8.027-3.996 9.73-8.272a48.223 48.223 0 00-.958-37.47c-9.515-20.741-42.019-24.022-59.171-11.868z"
				fill="#2f2e41"
			/>
		</svg>
	)
}

/*
|--------------------------------------------------------------------------
| EXPORT
|--------------------------------------------------------------------------
*/
const UndrawSecuritySVG = React.memo(UndrawSecurity)
export { UndrawSecuritySVG }
