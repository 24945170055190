import * as React from 'react'
import { Tab, Tabs, useTheme } from '@material-ui/core'
import CreditCardIcon from '@material-ui/icons/CreditCard'
import ImportExportIcon from '@material-ui/icons/ImportExport'
import { useTranslation } from 'react-i18next'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { DiruptUtils } from '@dirupt/utils'
import { StartonTabPanel } from 'components/Core/StartonTabPanel'
import { SAMDepositLemonwayCreditsCard } from 'components/Core/StartonCurrencyModal/deposit/SAMDepositLemonway/SAMDepositLemonwayCreditsCard'
import { SAMDepositLemonwayBank } from 'components/Core/StartonCurrencyModal/deposit/SAMDepositLemonway/SAMDepositLemonwayBank'
import { useUser } from 'stores/user/selectors'
import { useSnack } from 'stores/settings/selectors'
import { CurrencyEntity } from 'model/currencyEntity'

/*
|--------------------------------------------------------------------------
| CONTRACTS
|--------------------------------------------------------------------------
*/
export interface IStartonCurrencyModalDepositProps {
	currency: CurrencyEntity
	handleCloseModal: () => void
}

/*
|--------------------------------------------------------------------------
| COMPONENT
|--------------------------------------------------------------------------
*/
const SAMDepositLemonway: React.FC<IStartonCurrencyModalDepositProps> = (props: IStartonCurrencyModalDepositProps) => {
	const theme = useTheme()
	const [tabIndex, setTabIndex] = React.useState(0)
	const { user } = useUser()
	const { enqueueSnack } = useSnack()
	const router = useRouter()
	// METHODS
	// ----------------------------------------------------------------------------
	// eslint-disable-next-line @typescript-eslint/ban-types
	const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
		event.preventDefault()
		setTabIndex(newValue)
	}

	const { t } = useTranslation()

	useEffect(() => {
		if (user?.kyc !== 'ACCEPTED') {
			enqueueSnack({
				message: t('kyc.you_need_validate'),
				options: {
					variant: 'error',
				},
			})
			router.push('/user/settings/kyc')
		}
	}, [user])

	// RENDER
	// ----------------------------------------------------------------------------
	return (
		<React.Fragment>
			<Tabs
				value={tabIndex}
				onChange={handleChange}
				aria-label="deposit-method-tabs"
				variant="fullWidth"
				selectionFollowsFocus={false}
				className="mb-4"
			>
				<Tab label={t('wallet.credit_card')} icon={<CreditCardIcon />} {...DiruptUtils.a11yProps(0)} />
				<Tab label={t('wallet.bank_transfer')} icon={<ImportExportIcon />} {...DiruptUtils.a11yProps(1)} />
			</Tabs>

			<StartonTabPanel index={0} value={tabIndex} dir={theme.direction}>
				<SAMDepositLemonwayCreditsCard currency={props.currency} handleCloseModal={props.handleCloseModal} />
			</StartonTabPanel>
			<StartonTabPanel index={1} value={tabIndex} dir={theme.direction}>
				<SAMDepositLemonwayBank currency={props.currency} handleCloseModal={props.handleCloseModal} />
			</StartonTabPanel>
		</React.Fragment>
	)
}

export { SAMDepositLemonway }
