import { createStore, applyMiddleware, combineReducers, Middleware } from 'redux'
import { HYDRATE, createWrapper, MakeStore, Context } from 'next-redux-wrapper'
import thunkMiddleware from 'redux-thunk'
import tick from './tick/reducers'
import settings from './settings/reducers'
import user from './user/reducers'

/*
|--------------------------------------------------------------------------
| PREPARE MIDDLEWARES
|--------------------------------------------------------------------------
*/
const bindMiddleware = (middleware: Middleware[]) => {
	if (process.env.NODE_ENV !== 'production') {
		// eslint-disable-next-line @typescript-eslint/no-var-requires
		const { composeWithDevTools } = require('redux-devtools-extension')
		return composeWithDevTools(applyMiddleware(...middleware))
	}

	return applyMiddleware(...middleware)
}

/*
|--------------------------------------------------------------------------
| CREATE ROOT REDUCER
|--------------------------------------------------------------------------
*/
const rootReducer = combineReducers({
	tick,
	settings,
	user,
})
export type AppState = ReturnType<typeof rootReducer>

/*
|--------------------------------------------------------------------------
| CREATE NEXT REDUCER
|--------------------------------------------------------------------------
*/
const reducer = (state: any, action: any) => {
	if (action.type === HYDRATE) {
		const nextState = {
			...state, // use previous state
			...action.payload, // apply delta from hydration
		}
		// if (state.count.count) nextState.count.count = state.count.count // preserve count value on client side
		if (state.user) nextState.user = state.user
		// if (state.settings) nextState.settings = state.settings
		// if (state.organisation) nextState.organisation = state.organisation
		// navigation
		return nextState
	} else {
		return rootReducer(state, action)
	}
}

/*
|--------------------------------------------------------------------------
| CONFIGURE STORE AND CREATE THIS
|--------------------------------------------------------------------------
*/
const makeStore: MakeStore<AppState> = (_: Context) => createStore(reducer, bindMiddleware([thunkMiddleware]))

/*
|--------------------------------------------------------------------------
| EXPORT STORE WITH WRAPPER
|--------------------------------------------------------------------------
*/
export const wrapper = createWrapper<AppState>(makeStore, { debug: false })
