/**
 * Starton Tokenisation
 * API description
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CurrencyBlockchainData } from './currencyBlockchainData';
import { FrontCard } from './frontCard';

export interface CurrencyEntity { 
    symbol: string;
    name: string;
    image: string;
    type: CurrencyEntity.TypeEnum;
    currentPrice?: string;
    decimal: number;
    frontCard: FrontCard;
    blockchainData: CurrencyBlockchainData;
    createdAt: string;
    updatedAt: string;
}
export namespace CurrencyEntity {
    export type TypeEnum = 'crypto' | 'erc20' | 'fiat';
    export const TypeEnum = {
        Crypto: 'crypto' as TypeEnum,
        Erc20: 'erc20' as TypeEnum,
        Fiat: 'fiat' as TypeEnum
    };
}