import * as React from 'react'
import { makeStyles } from '@material-ui/styles'
import {
	AppBar,
	Box,
	ClickAwayListener,
	Grow,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	MenuItem,
	MenuList,
	Paper,
	Popper,
	SwipeableDrawer,
	Theme,
	Toolbar,
	Typography,
	useMediaQuery,
	useTheme,
} from '@material-ui/core'
import Cookies from 'js-cookie'
import { useRouter } from 'next/router'
import { useDispatch } from 'react-redux'
import MenuIcon from '@material-ui/icons/Menu'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import { useTranslation } from 'react-i18next'
import { StartonNavLink } from 'components/Core/StartonNav/StartonNavLink'
import { AccountSVG, WalletSVG } from 'components/SVG'
import { StartonLink, StartonWhiteLabelLogo } from 'components/Core'
import { StartonNavIcon } from 'components/Core/StartonNav/StartonNavIcon'
import { COOKIE_TOKEN_KEY, logoutUser } from 'stores/user'
import { useUser } from 'stores/user/selectors'
import { StartonTranslate } from 'components/Core/StartonTranslate'
import platform from '../../../../.tmp/platform'
/*
|--------------------------------------------------------------------------
| CONTRACTS
|--------------------------------------------------------------------------
*/
type StyleProps = Record<string, string>
type StyleClassKey =
	| 'navContainer'
	| 'logo'
	| 'menuTop'
	| 'menuPaper'
	| 'drawerContainer'
	| 'drawerLink'
	| 'drawerAppBar'
	| 'drawerPaper'
	| 'translation'
type PropClasses = Record<StyleClassKey, string>

/*
|--------------------------------------------------------------------------
| CONSTANTS
|--------------------------------------------------------------------------
*/
export const NAV_HEIGHT = 75
export const NAV_MARGIN = 40

/*
|--------------------------------------------------------------------------
| STYLES
|--------------------------------------------------------------------------
*/
const useStyles = makeStyles<Theme, StyleProps, StyleClassKey>((theme) => ({
	navContainer: {
		position: 'relative',
		width: '100%',
		height: NAV_HEIGHT,
		backgroundColor: theme.palette.background.default,
		boxShadow: '0px 10px 30px #0083BC1A',
		borderBottomLeftRadius: 20,
		borderBottomRightRadius: 20,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: '0px 50px',
		marginBottom: NAV_MARGIN,
	},
	logo: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		height: 49,
		'& img': {
			height: '49px',
		},
	},
	menuTop: {
		zIndex: 999,
	},
	menuPaper: {
		backgroundColor: theme.palette.background.default,
		boxShadow: '0px 10px 30px #0083BC1A',
		borderBottomLeftRadius: 20,
		borderBottomRightRadius: 20,
	},
	drawerPaper: {
		borderTopRightRadius: 20,
		borderTopLeftRadius: 20,
		overflow: 'hidden',
	},
	drawerContainer: {
		width: 'auto',
	},
	drawerAppBar: {
		background: `transparent linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%) 0% 0% no-repeat padding-box`,
		padding: theme.spacing(1, 0),
	},
	drawerLink: {
		color: theme.palette.text.primary,
		fontWeight: 500,
	},
	translation: {
		marginTop: '1.05rem',
	},
}))
/*
|--------------------------------------------------------------------------
| COMPONENT
|--------------------------------------------------------------------------
*/
const StartonNav: React.FC = () => {
	const dispatch = useDispatch()
	const router = useRouter()
	const theme = useTheme()
	const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
	const classes: PropClasses = useStyles({} as StyleProps)
	const { isAuthenticated, user } = useUser()
	const [open, setOpen] = React.useState(false)
	const [openMobileMenu, setOpenMobileMenu] = React.useState(false)
	const anchorRef = React.useRef<HTMLButtonElement>(null)
	const { t } = useTranslation()

	// Methods for account menu
	// ----------------------------------------------------------------------------
	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen)
	}

	const handleClose = (event: React.MouseEvent<EventTarget>) => {
		if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
			return
		}

		setOpen(false)
	}

	const handleListKeyDown = (event: React.KeyboardEvent) => {
		if (event.key === 'Tab') {
			event.preventDefault()
			setOpen(false)
		}
	}

	// Drawer methods
	// ----------------------------------------------------------------------------
	const handleDrawerOpen = () => {
		setOpenMobileMenu(true)
	}

	const handleDrawerClose = () => {
		setOpenMobileMenu(false)
	}

	// Return focus to the button when we transitioned from !open -> open
	// ----------------------------------------------------------------------------
	const prevOpen = React.useRef(open)
	React.useEffect(() => {
		if (prevOpen.current && !open && anchorRef.current && anchorRef.current.focus) {
			anchorRef.current!.focus()
		}

		prevOpen.current = open
	}, [open])

	// Menu item methods
	// ----------------------------------------------------------------------------
	const handleGoToAccount = (event: React.MouseEvent<EventTarget>) => {
		router.push('/user/settings/identity')
		handleClose(event)
	}

	const handleLogoutUser = async (event: React.MouseEvent<EventTarget>) => {
		// Logout user from redux
		dispatch(logoutUser())

		// Remove cookie
		Cookies.remove(COOKIE_TOKEN_KEY)

		// Close menu
		handleClose(event)

		// Redirect user to /login
		router.replace({
			pathname: '/login',
		})
	}

	// Render
	// ----------------------------------------------------------------------------
	return (
		<React.Fragment>
			<Box className={classes.navContainer}>
				{!isDesktop ? <StartonTranslate /> : null}
				{!isDesktop ? (
					<Box />
				) : (
					<Box className="flex flex-row">
						{/*<StartonNavLink href="/">FAQ</StartonNavLink>*/}

						<StartonNavLink href="/">{t('menu.home')}</StartonNavLink>
						{platform?.frontSettings?.menu?.map((menu) => (
							<StartonNavLink key={menu.href} href={menu.href}>
								{menu.name}
							</StartonNavLink>
						))}
						{['administrator', 'super_administrator'].includes(user?.role as string) && (
							<StartonNavLink href={platform?.frontSettings?.adminUrl || ''}>
								{t('menu.administration')}
							</StartonNavLink>
						)}
						{user?.role === 'project_holder_administrator' && (
							<StartonNavLink href={platform?.frontSettings?.adminUrl || ''}>
								{t('menu.campaigns')}
							</StartonNavLink>
						)}
					</Box>
				)}
				{/* Company logo */}
				<StartonLink href="/" className={classes.logo}>
					<StartonWhiteLabelLogo />
				</StartonLink>
				{!isDesktop ? (
					<IconButton color="inherit" aria-label="open menu drawer" onClick={handleDrawerOpen} edge="end">
						<MenuIcon fontSize="large" />
					</IconButton>
				) : (
					<Box className="flex flex-row">
						{isAuthenticated ? (
							<React.Fragment>
								<div className={classes.translation}>
									<StartonTranslate />
								</div>
								<StartonNavIcon
									type="link"
									linkProps={{
										href: '/wallets',
									}}
								>
									<WalletSVG />
								</StartonNavIcon>
								<StartonNavIcon
									type="button"
									buttonProps={{
										ref: anchorRef,
										onClick: handleToggle,
										'aria-controls': open ? 'account-menu' : undefined,
										'aria-haspopup': 'true',
									}}
								>
									<AccountSVG />
								</StartonNavIcon>
							</React.Fragment>
						) : (
							<StartonNavIcon
								type="link"
								linkProps={{
									href: '/login',
								}}
							>
								<AccountSVG />
							</StartonNavIcon>
						)}
					</Box>
				)}
			</Box>
			{!isDesktop ? (
				<SwipeableDrawer
					anchor="bottom"
					open={openMobileMenu}
					onOpen={handleDrawerOpen}
					onClose={handleDrawerClose}
					classes={{
						paper: classes.drawerPaper,
					}}
				>
					<Box className={classes.drawerContainer} role="presentation" onClick={handleDrawerClose}>
						<AppBar position="relative" className={classes.drawerAppBar}>
							<Toolbar>
								<Typography variant="h6">
									{t('tools.welcome')} {user?.profile?.firstName ?? ''}
								</Typography>
							</Toolbar>
						</AppBar>
						<List>
							{isAuthenticated && (
								<React.Fragment>
									<ListItem button component={StartonLink} href="/wallets">
										<ListItemText primary={t('mobile.wallet')} className={classes.drawerLink} />
									</ListItem>
									<ListItem button component={StartonLink} href="/user/settings/identity">
										<ListItemText primary={t('mobile.profile')} className={classes.drawerLink} />
									</ListItem>
									<ListItem button component={StartonLink} href="/user/settings/identity">
										<ListItemText primary={t('mobile.identity')} className={classes.drawerLink} />
									</ListItem>
									<ListItem button component={StartonLink} href="/user/settings/kyc">
										<ListItemText primary={t('mobile.legal')} className={classes.drawerLink} />
									</ListItem>
									<ListItem button component={StartonLink} href="/user/settings/change-password">
										<ListItemText primary={t('mobile.password')} className={classes.drawerLink} />
									</ListItem>
									{['administrator', 'super_administrator'].includes(user?.role as string) && (
										<ListItem
											button
											component={StartonLink}
											href={platform?.frontSettings?.adminUrl || ''}
										>
											<ListItemText
												primary={t('menu.administration')}
												className={classes.drawerLink}
											/>
										</ListItem>
									)}
									{user?.role === 'project_holder_administrator' && (
										<ListItem
											button
											component={StartonLink}
											href={platform?.frontSettings?.adminUrl || ''}
										>
											<ListItemText
												primary={t('menu.campaigns')}
												className={classes.drawerLink}
											/>
										</ListItem>
									)}
								</React.Fragment>
							)}

							{platform?.frontSettings?.menu?.map((menu) => (
								<ListItem key={menu.href} button component={StartonLink} href={menu.href}>
									<ListItemText primary={menu.name} className={classes.drawerLink} />
								</ListItem>
							))}
							{isAuthenticated && (
								<ListItem button onClick={handleLogoutUser}>
									<ListItemIcon>
										<ExitToAppIcon
											style={{ width: 50, height: 27, color: theme.palette.primary.main }}
											fill={theme.palette.primary.main}
										/>
									</ListItemIcon>
									<ListItemText primary={t('mobile.logout')} className={classes.drawerLink} />
								</ListItem>
							)}
						</List>
					</Box>
				</SwipeableDrawer>
			) : (
				<Popper
					open={open}
					anchorEl={anchorRef.current}
					role={undefined}
					transition
					disablePortal
					className={classes.menuTop}
				>
					{({ TransitionProps, placement }) => (
						<Grow
							{...TransitionProps}
							style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
						>
							<Paper className={classes.menuPaper}>
								<ClickAwayListener onClickAway={handleClose}>
									<MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
										<MenuItem onClick={handleGoToAccount}>{t('button.my_account')}</MenuItem>
										<MenuItem onClick={handleLogoutUser}>{t('button.logout')}</MenuItem>
									</MenuList>
								</ClickAwayListener>
							</Paper>
						</Grow>
					)}
				</Popper>
			)}
		</React.Fragment>
	)
}

/*
|--------------------------------------------------------------------------
| EXPORT
|--------------------------------------------------------------------------
*/
export { StartonNav }
