import { ethers } from 'ethers'
import { Web3ReactProvider } from '@web3-react/core'
import React from 'react'
import { CurrencyEntity } from 'model/currencyEntity'
import { SAMDepositEthereum } from 'components/Core/StartonCurrencyModal/deposit/SAMDepositEthereum'
import { SAMDepositDirectBankAccount } from 'components/Core/StartonCurrencyModal/deposit/SAMDepositDirectBankAccount'
import { SAMDepositLemonway } from 'components/Core/StartonCurrencyModal/deposit/SAMDepositLemonway/SAMDepositLemonway'

export interface IStartonCurrencyModalDepositProps {
	currency: CurrencyEntity
	balance: string
	handleCloseModal: () => void
}

function getLibrary(provider: any) {
	return new ethers.providers.Web3Provider(provider)
}

const SAMDeposit: React.FC<IStartonCurrencyModalDepositProps> = (props: IStartonCurrencyModalDepositProps) => {
	return (
		<React.Fragment>
			{props.currency.type !== CurrencyEntity.TypeEnum.Fiat && (
				<Web3ReactProvider getLibrary={getLibrary}>
					<SAMDepositEthereum currency={props.currency} handleCloseModal={props.handleCloseModal} />
				</Web3ReactProvider>
			)}
			{props.currency.type === CurrencyEntity.TypeEnum.Fiat && (
				<React.Fragment>
					{/*<SAMDepositDirectBankAccount currency={props.currency} handleCloseModal={props.handleCloseModal} />*/}
					<SAMDepositLemonway currency={props.currency} handleCloseModal={props.handleCloseModal} />
				</React.Fragment>
			)}
		</React.Fragment>
	)
}

export { SAMDeposit }
