import * as React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Box, InputAdornment, Theme, Typography } from '@material-ui/core'
import * as Yup from 'yup'
import { FormikHelpers } from 'formik/dist/types'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-material-ui'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import { DEFAULT_WALLET_FORM_CONFIG } from 'config'
import { StartonButton } from 'components/Core'
import { useSnack } from 'stores/settings/selectors'
import starton from 'data/axios'
import { CurrencyEntity } from 'model/currencyEntity'

/*
|--------------------------------------------------------------------------
| CONTRACTS
|--------------------------------------------------------------------------
*/
interface IForm {
	amount: number
}
export interface ISAMDepositLemonwayCreditsCardProps {
	currency: CurrencyEntity
	handleCloseModal: () => void
}
type StyleProps = ISAMDepositLemonwayCreditsCardProps
type StyleClassKey = 'hint' | 'buttonContainer' | 'buttonHelp' | 'modalAction' | 'maxAmount'
type PropClasses = Record<StyleClassKey, string>

/*
|--------------------------------------------------------------------------
| STYLES
|--------------------------------------------------------------------------
*/
const useStyles = makeStyles<Theme, StyleProps, StyleClassKey>((theme) => ({
	hint: {
		marginBottom: theme.spacing(2),
	},
	buttonContainer: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(2),
	},
	buttonHelp: {
		color: theme.palette.text.hint,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	modalAction: {
		width: '100%',
		// padding: theme.spacing(3, 4, 0),
	},
	maxAmount: {
		position: 'absolute',
		right: 0,
		top: -17,
		color: theme.palette.primary.main,
		cursor: 'pointer',
	},
}))

/*
|--------------------------------------------------------------------------
| COMPONENT
|--------------------------------------------------------------------------
*/
const SAMDepositLemonwayCreditsCard: React.FC<ISAMDepositLemonwayCreditsCardProps> = (
	_: ISAMDepositLemonwayCreditsCardProps,
) => {
	const classes: PropClasses = useStyles({} as StyleProps)
	const router = useRouter()
	const { enqueueSnack } = useSnack()
	const schemaValidations = React.useMemo(() => {
		return Yup.object().shape({
			amount: Yup.number()
				.min(DEFAULT_WALLET_FORM_CONFIG.minAmount, `Minimum ${DEFAULT_WALLET_FORM_CONFIG.minAmount}`)
				.max(DEFAULT_WALLET_FORM_CONFIG.maxAmount, `Maximum ${DEFAULT_WALLET_FORM_CONFIG.maxAmount}`)
				.required('Field required'),
		})
	}, [])

	// Methods
	// ----------------------------------------------------------------------------
	const handleMaxAmount = (formikProps: FormikHelpers<IForm>) => {
		formikProps.setFieldValue('amount', DEFAULT_WALLET_FORM_CONFIG.maxAmount)
	}

	const handleSubmit = (values: IForm, formikHelpers: FormikHelpers<IForm>) => {
		// Set submitting
		// ----------------------------------------------------------------------------
		formikHelpers.setSubmitting(true)
		console.log('Call API', values)
		starton
			.post(`/lemonway/deposit/credit-card`, {
				amount: values.amount.toString(),
			})
			.then((response) => {
				window.location.assign(response.data)
				console.log(response)
			})
			.catch((err) => {
				console.log(err)
				if (err.response.data.code === 'ERR_ACCOUNT_STATUS_INVALID') {
					enqueueSnack({
						message: 'You need to validate your KYC',
						options: {
							variant: 'error',
						},
					})
					router.push('/user/settings/kyc')
				}
			})
		//
		// setTimeout(() => {
		// 	if (props.handleCloseModal) {
		// 		props.handleCloseModal()
		// 	}
		// }, 2000)
	}

	// Render
	// ----------------------------------------------------------------------------
	const { t } = useTranslation()

	return (
		<Formik
			initialValues={{
				amount: Math.round(DEFAULT_WALLET_FORM_CONFIG.maxAmount / DEFAULT_WALLET_FORM_CONFIG.amountStep),
			}}
			validationSchema={schemaValidations}
			onSubmit={handleSubmit}
		>
			{(formikProps) => (
				<Form>
					<Box className={classes.buttonContainer}>
						<Box className="relative">
							<Typography
								onClick={() => handleMaxAmount(formikProps)}
								variant="caption"
								className={classes.maxAmount}
							>
								{t('withdraw.max_amount')}
							</Typography>
							<Field
								component={TextField}
								type="number"
								id="amount"
								name="amount"
								label={t('wallet.amount')}
								variant="outlined"
								inputProps={{
									step: DEFAULT_WALLET_FORM_CONFIG.amountStep,
									min: DEFAULT_WALLET_FORM_CONFIG.minAmount,
									max: DEFAULT_WALLET_FORM_CONFIG.maxAmount,
									type: 'number',
								}}
								InputProps={{
									endAdornment: <InputAdornment position="end">EUR</InputAdornment>,
								}}
								InputLabelProps={{
									shrink: true,
								}}
								placeholder={t('wallet.enter_amount')}
								fullWidth
							/>
						</Box>
					</Box>
					<StartonButton
						fullWidth
						type="submit"
						className={classes.modalAction}
						disabled={!formikProps.isValid}
						loading={formikProps.isSubmitting}
					>
						{t('wallet.send')}
					</StartonButton>
				</Form>
			)}
		</Formik>
	)
}

/*
|--------------------------------------------------------------------------
| EXPORT
|--------------------------------------------------------------------------
*/
export { SAMDepositLemonwayCreditsCard }
