import * as React from 'react'
import { makeStyles } from '@material-ui/styles'
import {
	Box,
	Button,
	ButtonGroup,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableFooter,
	TableHead,
	TablePagination,
	TableRow,
	Theme,
} from '@material-ui/core'
import clsx from 'clsx'

import { useTranslation } from 'react-i18next'
import organisation from '../../../../.tmp/organisation.json'
import { StartonCurrencyModal, StartonTablePagination } from 'components/Core'
import BigNumber from 'bignumber.js'

/*
|--------------------------------------------------------------------------
| CONTRACTS
|--------------------------------------------------------------------------
*/
export interface IStartonCurrenciesTableProps {
	currencies: any
}
type StyleProps = IStartonCurrenciesTableProps
type StyleClassKey = 'container' | 'tableContainer' | 'symbol'
type PropClasses = Record<StyleClassKey, string>

/*
|--------------------------------------------------------------------------
| STYLES
|--------------------------------------------------------------------------
*/
const useStyles = makeStyles<Theme, StyleProps, StyleClassKey>((theme) => ({
	container: {
		flex: 1,
		padding: theme.spacing(0, 8, 3),
	},
	tableContainer: {
		backgroundColor: theme.palette.background.default,
		boxShadow: '0px 6px 27px #0083BC1A',
		borderRadius: 20,
		overflow: 'hidden',
	},
	symbol: {
		width: 28,
		height: 28,
		backgroundSize: 'cover',
		marginRight: theme.spacing(1),
		[theme.breakpoints.up('sm')]: {
			width: 38,
			height: 38,
		},
	},
}))

/*
|--------------------------------------------------------------------------
| COMPONENT
|--------------------------------------------------------------------------
*/
const StartonCurrenciesTable: React.FC<IStartonCurrenciesTableProps> = (props: IStartonCurrenciesTableProps) => {
	const classes: PropClasses = useStyles(props as StyleProps)
	const [page, setPage] = React.useState(0)
	const [rowsPerPage, setRowsPerPage] = React.useState(5)
	const emptyRows = rowsPerPage - Math.min(rowsPerPage, props.currencies.length - page * rowsPerPage)
	const settings = organisation.settings
	const [openModal, setOpenModal] = React.useState<any>(false)

	// Get total of value
	// ----------------------------------------------------------------------------
	const totalOfValue = React.useMemo<number>(() => {
		// wallet.balance * wallet.currency.currentPrice
		return props.currencies.reduce((acc, item) => {
			const balance = item.balance
			const currentPrice = item.currency?.currentPrice ?? 0
			const calculate = balance * currentPrice

			return acc + calculate
		}, 0)
	}, [])

	// Methods
	// ----------------------------------------------------------------------------
	const handleChangePage = (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
		setPage(newPage)
	}
	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10))
		setPage(0)
	}

	const { t } = useTranslation()

	// Render
	// ----------------------------------------------------------------------------
	return (
		<Box className={classes.container}>
			<TableContainer component={Paper} className={classes.tableContainer}>
				<Table aria-label="currency-list">
					<TableHead>
						<TableRow>
							<TableCell />
							<TableCell align="left">{t('wallet.name')}</TableCell>
							<TableCell align="left">{t('wallet.symbol')}</TableCell>
							<TableCell align="left">{t('wallet.balance')}</TableCell>
							<TableCell align="left">{t('wallet.current_price')}</TableCell>
							<TableCell align="left">{t('wallet.value')}</TableCell>
							<TableCell align="left">{t('wallet.actions')}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{(rowsPerPage > 0
							? props.currencies.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
							: props.currencies
						).map((wallet) => (
							<TableRow key={wallet.currency.symbol}>
								<TableCell component="th" scope="row">
									<div
										className={classes.symbol}
										style={{ backgroundImage: `url(${wallet.currency.image})` }}
									/>
								</TableCell>
								<TableCell>{wallet.currency.name}</TableCell>
								<TableCell>{wallet.currency.symbol}</TableCell>
								<TableCell>{`${new BigNumber(wallet.balance)}`}</TableCell>
								<TableCell>
									{wallet.currency?.currentPrice ?? '0'} {settings.currency.symbol}
								</TableCell>
								<TableCell
									className={clsx({
										'opacity-50': !wallet.currency.currentPrice,
									})}
								>
									{wallet.currency.currentPrice
										? `${new BigNumber(wallet.balance * wallet.currency.currentPrice)} ${
												settings.currency.symbol
										  }`
										: t('wallet.value_unknown')}
								</TableCell>
								<TableCell>
									<ButtonGroup color="primary" aria-label="outlined primary button group">
										{wallet.currency.frontCard.actions.deposit && (
											<Button
												onClick={() =>
													setOpenModal({
														wallet: wallet,
														action: 'deposit',
													})
												}
											>
												{t('wallet.deposit')}
											</Button>
										)}
										{wallet.currency.frontCard.actions.withdraw && (
											<Button
												onClick={() =>
													setOpenModal({
														wallet: wallet,
														action: 'withdraw',
													})
												}
											>
												{t('wallet.withdraw')}
											</Button>
										)}
										<Button href={`/wallets/currency/${wallet.currency.symbol}/details`}>
											{t('tools.details')}
										</Button>
									</ButtonGroup>
								</TableCell>
							</TableRow>
						))}
						{emptyRows > 0 ? (
							<TableRow style={{ height: 53 * emptyRows }}>
								<TableCell colSpan={12} />
							</TableRow>
						) : (
							<TableRow style={{ border: 'none' }}>
								<TableCell colSpan={4} />
								<TableCell className="font-bold">Total</TableCell>
								<TableCell>
									{totalOfValue} {settings.currency.symbol}
								</TableCell>
								<TableCell colSpan={6} />
							</TableRow>
						)}
					</TableBody>
					<TableFooter>
						<TableRow style={{ border: 'none' }}>
							<TablePagination
								rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
								colSpan={12}
								count={props.currencies.length}
								rowsPerPage={rowsPerPage}
								page={page}
								SelectProps={{
									inputProps: { 'aria-label': 'rows per page' },
									native: true,
								}}
								onChangePage={handleChangePage}
								onChangeRowsPerPage={handleChangeRowsPerPage}
								ActionsComponent={StartonTablePagination}
							/>
						</TableRow>
					</TableFooter>
				</Table>
			</TableContainer>
			<StartonCurrencyModal
				open={openModal ? true : false}
				currency={openModal?.wallet?.currency}
				balance={openModal?.wallet?.balance}
				action={openModal?.action}
				handleCloseModal={() => setOpenModal(false)}
			/>
		</Box>
	)
}

/*
|--------------------------------------------------------------------------
| EXPORT
|--------------------------------------------------------------------------
*/
export { StartonCurrenciesTable }
