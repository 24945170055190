import * as React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Avatar, Box, Dialog, DialogContent, Theme, useMediaQuery, useTheme } from '@material-ui/core'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { StartonTitle } from 'components/Core'
import * as Yup from 'yup'
import { FormikHelpers } from 'formik/dist/types'
import starton from 'data/axios'
import { StartonWalletForm } from 'components/Core/StartonWalletModal/StartonWalletForm'

/*
|--------------------------------------------------------------------------
| CONTRACTS
|--------------------------------------------------------------------------
*/
export interface IStartonWalletModalProps {
	open: boolean
	// currency: CurrencyEntity,
	// balance: string
	// // wallet: IStartonWallet
	// action: false | "deposit" | "withdraw"
	handleCloseModal: () => void
}
type StyleProps = IStartonWalletModalProps
type StyleClassKey = 'modalMedia' | 'modalRoot' | 'modalPaper' | 'modalContent' | 'modalAction'
type PropClasses = Record<StyleClassKey, string>

/*
|--------------------------------------------------------------------------
| STYLES
|--------------------------------------------------------------------------
*/
const useStyles = makeStyles<Theme, StyleProps, StyleClassKey>((theme) => ({
	modalMedia: {
		borderRadius: 5000,
		width: 150,
		height: 150,
		margin: '-13% auto 10px auto',
		padding: theme.spacing(3),
		border: `3px solid ${theme.palette.background.default}`,
		boxShadow: '0px 12px 49.5px #0083BC1A',
		backgroundColor: theme.palette.background.default,
	},
	modalRoot: {
		[theme.breakpoints.down('sm')]: {
			alignItems: 'flex-end',
		},
	},
	modalPaper: {
		maxWidth: 650,
		textAlign: 'center',
		padding: theme.spacing(2, 2, 2, 2),
		overflow: 'scroll',
		[theme.breakpoints.down('sm')]: {
			maxWidth: '100%',
			margin: 0,
			width: '100%',
		},
		[theme.breakpoints.up('lg')]: {
			maxWidth: 650,
			padding: theme.spacing(0, 4, 4, 4),
			overflow: 'inherit !important',
		},
	},
	modalContent: {
		flex: '0 auto',
		[theme.breakpoints.down('sm')]: {
			paddingTop: '0px !important',
			padding: theme.spacing(0, 0, 1),
		},
	},
	modalAction: {
		width: '100%',
		// padding: theme.spacing(3, 4, 0),
		marginBottom: -60,
	},
}))

/*
|--------------------------------------------------------------------------
| COMPONENT
|--------------------------------------------------------------------------
*/
interface IForm {
	address: string
}

const StartonWalletModal: React.FC<IStartonWalletModalProps> = (props: IStartonWalletModalProps) => {
	const classes: PropClasses = useStyles(props as StyleProps)
	const theme = useTheme()
	const isTablet = useMediaQuery(theme.breakpoints.down('md'))
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

	const { t } = useTranslation()

	// RENDER
	// ----------------------------------------------------------------------------
	if (props.open) {
		return (
			<Dialog
				aria-labelledby="modal-wallet"
				maxWidth={isTablet ? false : 'md'}
				open={props.open}
				onClose={props.handleCloseModal}
				classes={{
					container: classes.modalRoot,
					paper: classes.modalPaper,
				}}
			>
				<Box className="relative h-full flex flex-col justify-end md:block md:h-auto">
					{!isMobile && (
						<Avatar
							className={clsx(classes.modalMedia, 'hidden md:block')}
							src="https://organisations-assets.s3.eu-west-3.amazonaws.com/global/eth.png"
							alt="ethereum"
						/>
					)}
					<DialogContent className={classes.modalContent}>
						<StartonTitle size="small" className="mb-2">
							Choose Wallet
						</StartonTitle>
						<StartonWalletForm handleCloseModal={props.handleCloseModal} />
					</DialogContent>
				</Box>
			</Dialog>
		)
	}
	return null
}

export { StartonWalletModal }
