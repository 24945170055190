import * as React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Box, Theme, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { NAV_HEIGHT } from 'components/Core/StartonNav/StartonNav'
import { StartonLink } from 'components/Core'
import platform from '../../../../.tmp/platform'

/*
|--------------------------------------------------------------------------
| CONTRACTS
|--------------------------------------------------------------------------
*/
type StyleProps = Record<string, string>
type StyleClassKey = 'footerContainer' | 'text' | 'privacyPolicy'
type PropClasses = Record<StyleClassKey, string>

/*
|--------------------------------------------------------------------------
| CONSTANTS
|--------------------------------------------------------------------------
*/
export const FOOTER_HEIGHT = 75
export const FOOTER_MARGIN = 40

/*
|--------------------------------------------------------------------------
| STYLES
|--------------------------------------------------------------------------
*/
const useStyles = makeStyles<Theme, StyleProps, StyleClassKey>((theme) => ({
	footerContainer: {
		position: 'relative',
		width: '100%',
		height: NAV_HEIGHT,
		backgroundColor: theme.palette.background.default,
		boxShadow: '0px 10px 30px #0083BC1A',
		borderTopLeftRadius: 20,
		borderTopRightRadius: 20,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: '0px 50px',
		marginTop: FOOTER_MARGIN,
		textAlign: 'center',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
			justifyContent: 'flex-start',
			height: 'auto',
			paddingBottom: '8px',
		},
	},
	text: {
		color: theme.palette.text.primary,
	},
	privacyPolicy: {
		color: theme.palette.text.primary,
		marginRight: '4rem',
	},
}))

/*
|--------------------------------------------------------------------------
| COMPONENT
|--------------------------------------------------------------------------
*/
const StartonFooter: React.FC = () => {
	const classes: PropClasses = useStyles({} as StyleProps)
	const { t } = useTranslation()

	return (
		<Box className={classes.footerContainer}>
			<Typography>
				{platform?.frontSettings?.copyright?.text ||
					`Copyright ${platform.frontSettings?.name || ''} © ${new Date().getFullYear()}.`}
			</Typography>
			<Box>
				<StartonLink href="/privacy-policy" className={classes.privacyPolicy}>
					{t('conditions.privacy_policy')}
				</StartonLink>
				<StartonLink href="/terms-and-conditions" className={classes.text}>
					{t('conditions.terms_and_conditions')}
				</StartonLink>
			</Box>
		</Box>
	)
}

/*
|--------------------------------------------------------------------------
| EXPORT
|--------------------------------------------------------------------------
*/
export { StartonFooter }
