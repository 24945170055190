import * as React from 'react'
import { Theme, useTheme } from '@material-ui/core'
import { ISVGProps } from 'components/SVG/contracts'

/*
|--------------------------------------------------------------------------
| CONTRACTS
|--------------------------------------------------------------------------
*/
type IUndrawCampaignEmptyProps = ISVGProps

/*
|--------------------------------------------------------------------------
| COMPONENT
|--------------------------------------------------------------------------
*/
const UndrawCampaignEmpty: React.FC<IUndrawCampaignEmptyProps> = (props: IUndrawCampaignEmptyProps) => {
	const theme: Theme = useTheme()
	const fill: string = React.useMemo<string>(() => props.fill || theme.palette.primary.main, [props])

	return (
		<svg width={794.122} height={505.345} viewBox="0 0 794.122 505.345" {...props}>
			<path
				d="M44.519 503.871C32.268 502.863 21.452 494 15.3 483.357c-2.063-3.569-3.476-8.25-1.14-11.647a6.41 6.41 0 0111.315 1.471 62.045 62.045 0 01-.965-12.64c.094-3.587.772-7.641 3.753-9.635 3.46-2.314 8.446-.477 10.962 2.838s3.199 7.626 3.803 11.744a10.83 10.83 0 0112.038-13.657c5.067.765 9.036 5.152 10.585 10.036s1.065 10.181.068 15.207c-1.168 5.894-3.065 11.74-6.48 16.684s-8.489 8.93-14.374 10.139zM794.122 106a106.034 106.034 0 01-71 100.08c-.66.23-1.33.46-2 .67V99.04h-138.77c.04-.67.09-1.34.15-2h138.62V76a5.002 5.002 0 00-5-5h-128.08c.23-.67.47-1.34.73-2a106.01 106.01 0 01205.35 37z"
				fill="#f2f2f2"
			/>
			<path
				d="M716.122 69h-487a7.008 7.008 0 00-7 7v330a7.008 7.008 0 007 7h487a7.008 7.008 0 007-7V76a7.008 7.008 0 00-7-7zm5 337a5.002 5.002 0 01-5 5h-487a5.002 5.002 0 01-5-5V76a5.002 5.002 0 015-5h487a5.002 5.002 0 015 5z"
				fill="#3f3d56"
			/>
			<path fill="#3f3d56" d="M223.122 97.04h499v2h-499z" />
			<circle cx={240.122} cy={84} r={6} fill="#3f3d56" />
			<circle cx={257.372} cy={84} r={6} fill="#3f3d56" />
			<circle cx={274.622} cy={84} r={6} fill="#3f3d56" />
			<path
				d="M359.122 138h-88a7.008 7.008 0 00-7 7v88a7.008 7.008 0 007 7h88a7.008 7.008 0 007-7v-88a7.008 7.008 0 00-7-7zm5 95a5.002 5.002 0 01-5 5h-88a5.002 5.002 0 01-5-5v-88a5.002 5.002 0 015-5h88a5.002 5.002 0 015 5z"
				fill="#3f3d56"
			/>
			<path
				d="M517.122 138.5h-88a6.513 6.513 0 00-6.5 6.5v88a6.513 6.513 0 006.5 6.5h88a6.513 6.513 0 006.5-6.5v-88a6.513 6.513 0 00-6.5-6.5z"
				fill={fill}
			/>
			<path
				d="M675.122 138.5h-88a6.513 6.513 0 00-6.5 6.5v88a6.513 6.513 0 006.5 6.5h88a6.513 6.513 0 006.5-6.5v-88a6.513 6.513 0 00-6.5-6.5zM359.122 270.5h-88a6.513 6.513 0 00-6.5 6.5v88a6.513 6.513 0 006.5 6.5h88a6.513 6.513 0 006.5-6.5v-88a6.513 6.513 0 00-6.5-6.5z"
				fill="#e6e6e6"
			/>
			<path
				d="M517.122 270.5h-88a6.513 6.513 0 00-6.5 6.5v88a6.513 6.513 0 006.5 6.5h88a6.513 6.513 0 006.5-6.5v-88a6.513 6.513 0 00-6.5-6.5z"
				fill={fill}
			/>
			<path
				d="M675.122 270.5h-88a6.513 6.513 0 00-6.5 6.5v88a6.513 6.513 0 006.5 6.5h88a6.513 6.513 0 006.5-6.5v-88a6.513 6.513 0 00-6.5-6.5z"
				fill="#e6e6e6"
			/>
			<path
				d="M337.622 285h-88a6.507 6.507 0 01-6.5-6.5v-88a6.507 6.507 0 016.5-6.5h88a6.507 6.507 0 016.5 6.5v88a6.507 6.507 0 01-6.5 6.5z"
				fill={fill}
			/>
			<path fill="#a0616a" d="M202.746 492.088l11.72-3.597-8.296-46.918-17.298 5.308 13.874 45.207z" />
			<path
				d="M240.41 492.438l-36.836 11.304-4.367-14.232 22.603-6.937a14.887 14.887 0 0118.6 9.865z"
				fill="#2f2e41"
			/>
			<path fill="#a0616a" d="M102.748 492.358l12.26-.001 5.832-47.288-18.094.001.002 47.288z" />
			<path d="M138.652 503.74l-38.53.002-.001-14.887h23.643a14.887 14.887 0 0114.888 14.885z" fill="#2f2e41" />
			<path
				d="M101.868 355.28a9.162 9.162 0 01.318-14.045l9.506-119.695 19.33 4.83-15.477 116.759a9.212 9.212 0 01-13.677 12.151z"
				fill="#a0616a"
			/>
			<circle cx={140.579} cy={150.555} r={24.561} fill="#a0616a" />
			<path
				d="M119.223 217.859c-5.542-7.609-5.713-18.087.257-25.364 3.896-4.75 10.412-8.55 21.292-8.55 29 0 40 23 40 23s12 22 5 42-7 22-7 22l-46-4s7.76-19.832-13.55-49.086z"
				fill="#ccc"
			/>
			<path
				d="M105.038 237.767l9.91-32.42a15.502 15.502 0 0121.93-9.246 15.53 15.53 0 017.087 20.01l-12.598 28.691z"
				fill="#ccc"
			/>
			<path d="M177.772 265.945l39 202-21 6-51-133-23 136-22 3s-9.65-179.945 33-213z" fill="#2f2e41" />
			<path
				d="M233.903 256.477a10.527 10.527 0 00-.962 1.35l-49.535 2.401-9.539-29.12-16.562 7.872 15.588 43.372 61.262-11.981a10.496 10.496 0 10-.252-13.894z"
				fill="#a0616a"
			/>
			<path
				d="M150.375 209.85a15.5 15.5 0 0116.362-17.283 15.53 15.53 0 0114.535 15.472v31.335l-26.936 4.144z"
				fill="#ccc"
			/>
			<path
				d="M145.98 175.339c-3.686-.016-6.53-3.897-6.364-7.58s2.638-6.923 5.618-9.093 6.475-3.492 9.798-5.087 6.618-3.586 8.67-6.648a14.716 14.716 0 001.023-13.505 21.669 21.669 0 00-9.333-10.224 28.378 28.378 0 00-37.607 8.25l-4.208 11.458c-4.288 5.156-4.968 12.814-2.333 18.98s8.249 10.806 14.582 13.011a35.794 35.794 0 0019.84.595"
				fill="#2f2e41"
			/>
			<path
				d="M123.534 137.179c-1.809-2.9-4.944-4.744-8.186-5.826a25.724 25.724 0 00-33.832 23.003c-.344 6.314 1.633 12.49 2.795 18.705s1.393 13.082-2.034 18.396c-2.61 4.048-6.964 6.597-11.261 8.773-3.581 1.813-7.455 3.516-11.453 3.154s-8.017-3.528-7.91-7.54a32.054 32.054 0 00-2.682 9.08c-.281 3.155.496 6.56 2.776 8.761 3.313 3.199 8.562 2.965 13.116 2.275 9.857-1.494 19.838-4.296 27.625-10.52s12.955-16.475 10.904-26.232c-.857-4.075-2.87-7.796-4.497-11.63s-2.897-8.026-2.15-12.123a14.735 14.735 0 017.493-9.982 19.601 19.601 0 0112.523-1.9c2.799.46 6.294 1.173 7.954-1.127a4.508 4.508 0 00-.094-4.808 13.248 13.248 0 00-3.592-3.56"
				fill="#2f2e41"
			/>
			<path d="M308.485 505.345H1.19a1.19 1.19 0 110-2.381h307.294a1.19 1.19 0 110 2.381z" fill="#3f3d56" />
		</svg>
	)
}

/*
|--------------------------------------------------------------------------
| EXPORT
|--------------------------------------------------------------------------
*/
const UndrawCampaignEmptySVG = React.memo(UndrawCampaignEmpty)
export { UndrawCampaignEmptySVG }
