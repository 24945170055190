/**
 * Starton Tokenisation
 * API description
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface WalletEntity { 
    id: string;
    owner: string;
    address: string;
    externalId?: string;
    isVerified: boolean;
    type: WalletEntity.TypeEnum;
    metadata?: any;
    createdAt: string;
    updatedAt: string;
}
export namespace WalletEntity {
    export type TypeEnum = 'external' | 'kms';
    export const TypeEnum = {
        External: 'external' as TypeEnum,
        Kms: 'kms' as TypeEnum
    };
}