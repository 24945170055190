import * as React from 'react'
import { Box, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import { ICampaign } from 'types'

/*
|--------------------------------------------------------------------------
| CONTRACT
|--------------------------------------------------------------------------
*/
export interface IStartonCampaignProgressBarProps extends Pick<ICampaign, 'raised' | 'softCap' | 'hardCap'> {
	className?: string
}
type StyleProps = IStartonCampaignProgressBarProps & { raisedPercentage: number; highRaisedPercentage: number }
type StyleClassKey =
	| 'campaignProgressBarContainer'
	| 'campaignProgressBarFallback'
	| 'campaignProgressBarNormal'
	| 'campaignProgressBarHigh'
type PropClasses = Record<StyleClassKey, string>

/*
|--------------------------------------------------------------------------
| STYLES
|--------------------------------------------------------------------------
*/
const useStyles = makeStyles<Theme, StyleProps, StyleClassKey>((theme) => ({
	campaignProgressBarContainer: {
		position: 'relative',
		width: '90%',
		height: 22,
		display: 'flex',
		flexDirection: 'row',
		overflow: 'hidden',
		margin: '0 auto',
		borderRadius: 20,
	},
	campaignProgressBarFallback: {
		position: 'absolute',
		height: 22,
		width: '100%',
		border: '2px solid #FFFFFF',
		borderRadius: 20,
		backgroundColor: theme.palette.background.paper,
	},
	campaignProgressBarNormal: {
		position: 'absolute',
		height: 22,
		width: (props: StyleProps) => (props.raisedPercentage > 100 ? '100%' : `${props.raisedPercentage}%`),
		border: '1px solid #FFFFFF',
		borderRadius: 20,
		background: `transparent linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%) 0% 0% no-repeat padding-box`,
	},
	campaignProgressBarHigh: {
		position: 'absolute',
		height: 22,
		width: (props: StyleProps) => `${props.highRaisedPercentage}%`,
		border: '1px solid #FFFFFF',
		borderRadius: 20,
		background: `transparent linear-gradient(90deg, #FF9D00 0%, #FFEB00 100%) 0% 0% no-repeat padding-box`,
	},
}))

/*
|--------------------------------------------------------------------------
| COMPONENT
|--------------------------------------------------------------------------
*/
const StartonCampaignProgressBar: React.FC<IStartonCampaignProgressBarProps> = (
	props: IStartonCampaignProgressBarProps,
) => {
	const raisedPercentage: number = React.useMemo<number>(() => {
		const cap = props.hardCap || props.softCap
		return Math.floor((props.raised / cap) * 100)
	}, [props.raised, props.softCap, props.hardCap])
	const highRaisedPercentage: number = React.useMemo<number>(() => {
		return raisedPercentage > 100 ? raisedPercentage % 100 : 0
	}, [raisedPercentage])
	const classes: PropClasses = useStyles({
		raisedPercentage,
		highRaisedPercentage,
	} as StyleProps)

	return (
		<Box className={clsx(classes.campaignProgressBarContainer, props.className)}>
			<Box className={classes.campaignProgressBarFallback} />
			<Box className={classes.campaignProgressBarNormal} />
			<Box className={classes.campaignProgressBarHigh} />
		</Box>
	)
}

/*
|--------------------------------------------------------------------------
| EXPORT
|--------------------------------------------------------------------------
*/
export { StartonCampaignProgressBar }
