import * as React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Box, Container, Theme } from '@material-ui/core'
import { useRouter } from 'next/router'
import { AppProps } from 'next/app'
import { NAV_HEIGHT, NAV_MARGIN, StartonNav } from 'components/Core/StartonNav/StartonNav'
import { FOOTER_HEIGHT, FOOTER_MARGIN, StartonFooter } from 'components/Core/StartonFooter/StartonFooter'

/*
|--------------------------------------------------------------------------
| CONTRACTS
|--------------------------------------------------------------------------
*/
type StyleProps = Record<string, string>
type StyleClassKey = 'container' | 'componentContainer'
type PropClasses = Record<StyleClassKey, string>

/*
|--------------------------------------------------------------------------
| STYLES
|--------------------------------------------------------------------------
*/
const useStyles = makeStyles<Theme, StyleProps, StyleClassKey>((theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		minHeight: '100vh',
		[theme.breakpoints.down('sm')]: {
			paddingLeft: 0,
			paddingRight: 0,
		},
	},
	componentContainer: {
		position: 'relative',
		minHeight: `calc(100vh - ${NAV_HEIGHT + NAV_MARGIN + FOOTER_HEIGHT + FOOTER_MARGIN}px)`,
	},
}))

/*
|--------------------------------------------------------------------------
| COMPONENT
|--------------------------------------------------------------------------
*/
const StartonLayout: React.FC<AppProps> = ({ Component, pageProps }: AppProps) => {
	const classes: PropClasses = useStyles({} as StyleProps)
	const router = useRouter()
	const isAuthPage = React.useMemo<boolean>(() => {
		return ['/login', '/register', '/forgot-password', '/reset-password', '/waiting-confirmation'].includes(
			router.pathname,
		)
	}, [router.pathname])

	return !isAuthPage ? (
		<Container maxWidth="xl" className={classes.container}>
			<StartonNav />
			<Box className={classes.componentContainer}>
				{/*<StartonTransitionPage>*/}
				<Component key={router.pathname} {...pageProps} />
				{/*</StartonTransitionPage>*/}
			</Box>
			<StartonFooter />
		</Container>
	) : (
		<Component {...pageProps} />
	)
}

/*
|--------------------------------------------------------------------------
| EXPORT
|--------------------------------------------------------------------------
*/
export { StartonLayout }
