import * as React from 'react'
import { Theme, useTheme } from '@material-ui/core'
import { ISVGProps } from 'components/SVG/contracts'

/*
|--------------------------------------------------------------------------
| CONTRACTS
|--------------------------------------------------------------------------
*/
type IUndrawErrorProps = ISVGProps

/*
|--------------------------------------------------------------------------
| COMPONENT
|--------------------------------------------------------------------------
*/
const UndrawError: React.FC<IUndrawErrorProps> = (props: IUndrawErrorProps) => {
	const theme: Theme = useTheme()
	const fill: string = React.useMemo<string>(() => props.fill || theme.palette.primary.main, [props])

	return (
		<svg width={1080.049} height={748.002} viewBox="0 0 1080.049 748.002" {...props}>
			<path
				d="M772.232 511.47c0 67.692-40.242 91.328-89.884 91.328s-89.884-23.636-89.884-91.329 89.884-153.809 89.884-153.809 89.884 86.116 89.884 153.81z"
				fill={fill}
			/>
			<path
				fill="#3f3d56"
				d="M679.994 535.79l38.311-70.088-38.167 61.201.414-25.473 26.404-50.708-26.294 43.966.744-45.815 28.274-40.369-28.157 33.165.465-84.009-2.797 106.449-28.631-43.825 28.285 52.808-2.679 51.167-.079-1.358-33.139-46.304 33.038 51.102-.335 6.399-.06.097.028.525-6.796 68.28h9.079l1.09-5.516 32.957-50.977-32.875 45.936.92-56.653z"
			/>
			<path
				d="M1080.049 293.814c0 129.31-76.873 174.46-171.701 174.46s-171.7-45.15-171.7-174.46S908.347 0 908.347 0s171.7 164.503 171.7 293.814z"
				fill="#f2f2f2"
			/>
			<path
				fill="#3f3d56"
				d="M902.093 448.494l1.758-108.221 73.184-133.885-72.908 116.908.791-48.661 50.437-96.864-50.228 83.987v.001l1.422-87.519 54.009-77.116-53.786 63.354L907.661 0l-5.583 212.444.459-8.764-54.912-84.052 54.031 100.875-5.116 97.743-.153-2.594-63.303-88.452 63.112 97.617-.64 12.224-.115.184.053 1.003-12.981 247.982h17.343l2.081-128.087 62.957-97.378-62.801 87.749z"
			/>
			<path
				d="M1073 564.558c0 38.596-250.37 183.444-543.052 183.444S0 673.037 0 634.442s231.442 7.28 524.125 7.28S1073 525.962 1073 564.557z"
				fill="#3f3d56"
			/>
			<path
				d="M1073 564.558c0 38.596-250.37 183.444-543.052 183.444S0 673.037 0 634.442s231.442 7.28 524.125 7.28S1073 525.962 1073 564.557z"
				opacity={0.1}
			/>
			<path
				d="M1073 564.558c0 38.596-250.37 139.767-543.052 139.767S0 673.037 0 634.442s231.442-36.398 524.125-36.398S1073 525.963 1073 564.558z"
				fill="#3f3d56"
			/>
			<ellipse cx={535.878} cy={641.774} rx={225.044} ry={17.407} opacity={0.1} />
			<path d="M357.598 292.966s65.883 1.22 53.683 12.81-57.953 0-57.953 0z" fill="#a0616a" />
			<path
				d="M220.328 152.183s26.6-4.75 35.85 16.724 28.244 82.715 28.244 82.715 50.833 33.648 60.079 32.543 25.051 1.15 22.152 11.237-1.612 21.525-8.287 22.927-17.29-11.98-25.415-5.535-80.53-17.096-85.178-21.974-43.28-110.501-43.28-110.501 1.546-28.48 15.835-28.136z"
				fill={fill}
			/>
			<path
				fill="#a0616a"
				d="M277.379 605.607l3.05 25.621-25.621 6.711-14.641-6.711v-22.571l37.212-3.05zM208.445 622.078l3.05 25.621-25.621 6.711-14.641-6.711v-22.571l37.212-3.05z"
			/>
			<path
				d="M279.819 325.602l11.59 42.093 17.081 85.404s4.88 20.741-4.88 45.752-27.451 106.756-23.791 111.636-41.482 8.54-40.872 3.66 6.71-71.983 6.71-71.983 8.54-59.173 12.81-66.494-19.52-25.621-19.52-25.621-6.1 70.154-15.251 76.254-7.93 100.655-12.2 101.875-35.383 6.1-39.043 0 0-107.975 0-107.975 20.741-77.475 16.471-88.455-1.83-57.953-1.83-57.953-15.86-35.992-7.32-54.903z"
				fill="#2f2e41"
			/>
			<path
				d="M277.989 91.35s-25.621 31.722-24.401 45.752-40.263-25.62-40.263-25.62 29.892-40.263 29.892-48.803 34.772 28.671 34.772 28.671z"
				fill="#a0616a"
			/>
			<circle cx={277.684} cy={62.984} r={40.262} fill="#a0616a" />
			<path
				d="M176.217 302.781c-1.824 6.796-4.087 14.5-3.764 20.991.208 4.203 11.365 6.96 26.793 8.766 14.305 1.678 32.283 2.532 48.626 2.971 17.16.464 32.502.464 39.877.464 21.962 0 4.27-14.03-4.27-20.741s-6.71-101.875-6.1-114.076-5.49-47.583-5.49-54.293-14.355-24.224-14.355-24.224-3.336 9.583-16.757-3.227-31.112-16.471-31.112-16.471c-13.42 4.88-35.992 58.563-37.212 67.713-.494 3.715-.085 22.742.745 44.466 1.201 31.752 3.3 69.287 4.746 73.27 1.14 3.142-.122 8.413-1.727 14.391z"
				fill={fill}
			/>
			<path fill="#a0616a" d="M241.387 338.413l4.27 15.251-16.471 17.081-5.49-23.792 17.691-8.54z" />
			<path
				d="M246.877 624.518s12.81 14.64 22.571 4.88 9.15-15.86 10.981-15.86 29.282 24.4 29.282 24.4 46.972 10.981 21.35 18.912-98.215 2.44-98.215 0-1.83-34.772 4.88-34.772z"
				fill="#2f2e41"
			/>
			<path
				d="M177.944 640.989s12.81 14.64 22.57 4.88 9.151-15.86 10.981-15.86 29.282 24.4 29.282 24.4 46.972 10.981 21.351 18.912-98.215 2.44-98.215 0-1.83-34.772 4.88-34.772zM232.48 95.123c-1.3.84-2.993 1.692-4.295.854a194.661 194.661 0 01-10.803-37.403c-.823-4.496-1.393-9.493 1.104-13.32.985-1.51 2.402-2.743 3.124-4.395 1.092-2.501.373-5.397.736-8.102.76-5.667 6.118-9.62 11.505-11.537s11.239-2.512 16.374-5.027c4.876-2.389 8.78-6.35 13.302-9.356s10.313-5.062 15.348-3.03c4.455 1.796 7.23 6.337 11.44 8.648 2.857 1.57 6.172 2.006 9.361 2.68a59.922 59.922 0 0124.34 11.307 17.673 17.673 0 014.43 4.567c5.403 8.895-4.27 20.989-.003 30.48l-9.227-7.28a32.13 32.13 0 00-8.24-5.237c-3.056-1.157-6.676-1.297-9.452.427-3.896 2.42-4.952 7.476-6.313 11.856s-4.371 9.237-8.956 9.125c-6.236-.153-8.416-8.855-14.012-11.61-3.648-1.796-8.24-.614-11.35 2.006-3.11 2.62-4.975 6.42-6.344 10.248-.855 2.393-1.658 5.09-3.177 7.164-1.675 2.287-3.818 2.471-5.607 4.18-4.658 4.445-7.522 9.032-13.284 12.755z"
				fill="#2f2e41"
			/>
			<path
				d="M176.217 302.781c7.583 10.999 16.13 22.346 23.029 29.757 14.305 1.678 32.283 2.532 48.626 2.971a38.527 38.527 0 00-10.755-9.907c-7.93-4.88-26.842-62.833-26.842-62.833s23.182-59.783 29.282-82.354-17.691-35.382-17.691-35.382c-10.98-9.15-29.892 12.2-29.892 12.2s-9.65 29.111-18.776 57.886c1.201 31.753 3.3 69.288 4.746 73.271 1.14 3.142-.122 8.413-1.727 14.391z"
				opacity={0.1}
			/>
			<path
				d="M220.036 140.153s23.791 12.81 17.69 35.382-29.28 82.354-29.28 82.354 18.91 57.953 26.84 62.833 18.912 16.471 10.371 22.571-14.64 15.861-20.74 12.811-6.101-20.131-16.472-20.131-52.463-63.443-53.073-70.154 34.772-113.466 34.772-113.466 18.911-21.35 29.892-12.2z"
				fill={fill}
			/>
			<path
				d="M714.746 306.892l-6.863-6.863a21.345 21.345 0 00-30.186 0L544.582 433.144 411.467 300.03a21.345 21.345 0 00-30.187 0l-6.862 6.863a21.345 21.345 0 000 30.186l133.115 133.115L374.418 603.31a21.345 21.345 0 000 30.186l6.862 6.863a21.345 21.345 0 0030.187 0l133.115-133.116 133.115 133.116a21.345 21.345 0 0030.186 0l6.863-6.863a21.345 21.345 0 000-30.186L581.63 470.193l133.115-133.115a21.345 21.345 0 000-30.186z"
				fill="#ff6584"
			/>
			<path
				d="M369.667 610.546L498.83 481.383l-1.243-1.243L374.418 603.31a21.26 21.26 0 00-4.75 7.237zM372.577 311.22a21.345 21.345 0 0130.186 0l133.115 133.114L668.993 311.22a21.345 21.345 0 0130.187 0l6.863 6.863a21.344 21.344 0 014.75 22.95l3.953-3.954a21.345 21.345 0 000-30.186l-6.863-6.863a21.345 21.345 0 00-30.186 0L544.582 433.144 411.467 300.03a21.345 21.345 0 00-30.187 0l-6.862 6.863a21.26 21.26 0 00-4.751 7.237zM582.874 471.437l-9.947 9.946L706.042 614.5a21.344 21.344 0 014.751 22.949l3.953-3.953a21.345 21.345 0 000-30.186z"
				opacity={0.1}
			/>
			<path
				d="M155 560.192c0 56.222-33.423 75.852-74.652 75.852s-74.652-19.63-74.652-75.852 74.652-127.744 74.652-127.744S155 503.971 155 560.192z"
				fill={fill}
			/>
			<path
				fill="#3f3d56"
				d="M78.393 580.392l31.819-58.211-31.699 50.83.344-21.157 21.929-42.115-21.838 36.516.618-38.051 23.482-33.529-23.385 27.546.386-69.773-2.323 88.41-23.779-36.398 23.491 43.858-2.224 42.497-.066-1.128-27.523-38.457 27.439 42.442-.278 5.315-.05.08.023.436-5.644 56.709h7.541l.905-4.581 27.372-42.338-27.304 38.151.764-47.052z"
			/>
		</svg>
	)
}

/*
|--------------------------------------------------------------------------
| EXPORT
|--------------------------------------------------------------------------
*/
const UndrawErrorSVG = React.memo(UndrawError)
export { UndrawErrorSVG }
