import * as React from 'react'
import { Theme, useTheme } from '@material-ui/core'
import { ISVGProps } from 'components/SVG/contracts'

/*
|--------------------------------------------------------------------------
| CONTRACTS
|--------------------------------------------------------------------------
*/
type IGoogleProps = ISVGProps

/*
|--------------------------------------------------------------------------
| COMPONENT
|--------------------------------------------------------------------------
*/
const Google: React.FC<IGoogleProps> = (props: IGoogleProps) => {
	const theme: Theme = useTheme()
	const fill: string = React.useMemo<string>(() => props.fill || theme.palette.text.primary, [props])

	return (
		<svg viewBox="0 0 28.306 28.77" width={22} height={22} {...props}>
			<path
				d="M28.306 14.722c0 8.208-5.621 14.049-13.921 14.049a14.385 14.385 0 010-28.77 13.834 13.834 0 019.646 3.765l-3.915 3.765C14.994 2.587 5.47 6.3 5.47 14.385a9.011 9.011 0 008.915 9.084c5.7 0 7.831-4.084 8.167-6.2h-8.167V12.32H28.08a12.611 12.611 0 01.226 2.402z"
				fill={fill}
			/>
		</svg>
	)
}

/*
|--------------------------------------------------------------------------
| EXPORT
|--------------------------------------------------------------------------
*/
const GoogleSVG = React.memo(Google)
export { GoogleSVG }
