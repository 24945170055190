/*
|--------------------------------------------------------------------------
| STORE CONTRACTS
|--------------------------------------------------------------------------
*/

// State interfaces
// ----------------------------------------------------------------------------
export interface ITickState {
	lastUpdate: number
}

// Actions constants
// ----------------------------------------------------------------------------
export const TICK = '[TICK] TICK'

// Actions interfaces
// ----------------------------------------------------------------------------
export interface IServerRenderClockAction {
	type: typeof TICK
	ts: number
}

/*
|--------------------------------------------------------------------------
| Actions type
|--------------------------------------------------------------------------
*/
export type TickActionType = IServerRenderClockAction
