import * as React from 'react'
import { Theme, useTheme } from '@material-ui/core'
import { ISVGProps } from 'components/SVG/contracts'

/*
|--------------------------------------------------------------------------
| CONTRACTS
|--------------------------------------------------------------------------
*/
type IAccountProps = ISVGProps

/*
|--------------------------------------------------------------------------
| COMPONENT
|--------------------------------------------------------------------------
*/
const Account: React.FC<IAccountProps> = (props: IAccountProps) => {
	const theme: Theme = useTheme()
	const fill: string = React.useMemo<string>(() => props.fill || theme.palette.text.primary, [props])

	return (
		<svg width={50} height={50} viewBox="0 0 50 50" {...props} fill={fill}>
			<g transform="translate(-1725 -30)">
				<circle cx={25} cy={25} r={25} transform="translate(1725 30)" fill="none" stroke="none" />
				<path
					stroke="none"
					d="M1750 41a14 14 0 1014 14 14 14 0 00-14-14zm-6 24.38v-2a3.22 3.22 0 013-3.38h6a3.22 3.22 0 013 3.39v2a11.92 11.92 0 01-12 0zm14-1.46v-.61a5.21 5.21 0 00-5-5.31h-6a5.2 5.2 0 00-5 5.31v.59a12 12 0 1116 0z"
				/>
				<path stroke="none" d="M1750 46a5 5 0 105 5 5 5 0 00-5-5zm0 8a3 3 0 113-3 3 3 0 01-3 3z" />
				<path stroke="none" d="M1734 39h32v32h-32z" fill="none" />
			</g>
		</svg>
	)
}

/*
|--------------------------------------------------------------------------
| EXPORT
|--------------------------------------------------------------------------
*/
const AccountSVG = React.memo(Account)
export { AccountSVG }
