import { SnackbarKey } from 'notistack'
import {
	CLOSE_SNACKBAR,
	ENQUEUE_SNACKBAR,
	ICloseSnackbarActions,
	IEnqueueSnackbarActions,
	IRemoveSnackbarActions,
	ISetSettingsActions,
	ISettingsState,
	ISnackbar,
	REMOVE_SNACKBAR,
	SET_SETTINGS,
} from './contracts'

/**
 * Set settings
 * @param payload
 */
export const setSettings = (payload: ISettingsState): ISetSettingsActions => {
	return {
		type: SET_SETTINGS,
		payload,
	}
}

/**
 * Add new snackbar
 * @param payload
 */
export const enqueueSnackbar = (payload: ISnackbar): IEnqueueSnackbarActions => {
	const key = payload.options && payload.options.key

	return {
		type: ENQUEUE_SNACKBAR,
		payload: {
			...payload,
			key: key || new Date().getTime() + Math.random(),
		},
	}
}

/**
 * Close specific snackbar with key payload
 * @param payload
 */
export const closeSnackbar = (payload: SnackbarKey): ICloseSnackbarActions => {
	return {
		type: CLOSE_SNACKBAR,
		dismissAll: !payload,
		payload,
	}
}

/**
 * Remove specific snackbar with key payload
 * @param payload
 */
export const removeSnackbar = (payload: SnackbarKey): IRemoveSnackbarActions => {
	return {
		type: REMOVE_SNACKBAR,
		payload,
	}
}
