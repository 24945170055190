import * as React from 'react'
import { Theme, useTheme } from '@material-ui/core'
import { ISVGProps } from 'components/SVG/contracts'

/*
|--------------------------------------------------------------------------
| CONTRACTS
|--------------------------------------------------------------------------
*/
type IDocumentProps = ISVGProps

/*
|--------------------------------------------------------------------------
| COMPONENT
|--------------------------------------------------------------------------
*/
const Document: React.FC<IDocumentProps> = (props: IDocumentProps) => {
	const theme: Theme = useTheme()
	const fill: string = React.useMemo<string>(() => props.fill || theme.palette.primary.main, [props])

	return (
		<svg width={114.831} height={139.249} viewBox="0 0 114.831 139.249" {...props}>
			<g>
				<path
					d="M84.253 0H2.86A2.863 2.863 0 000 2.86v109.991a2.863 2.863 0 002.86 2.86h81.394a2.863 2.863 0 002.86-2.86V2.86A2.863 2.863 0 0084.254 0z"
					fill="#f2f2f2"
				/>
				<path
					d="M5.374 3.52A2 2 0 003.41 5.547v104.618a2 2 0 001.964 2.026h76.365a2 2 0 001.964-2.027V5.547a2 2 0 00-1.964-2.027z"
					fill="#fff"
				/>
				<path
					d="M9.349 8.359a1.414 1.414 0 00-1.32 1.49v14.4a1.414 1.414 0 001.32 1.49h68.415a1.414 1.414 0 001.32-1.49v-14.4a1.414 1.414 0 00-1.32-1.49zM9.349 32.722a1.341 1.341 0 00-1.32 1.358v30.777a1.341 1.341 0 001.32 1.358h68.415a1.341 1.341 0 001.32-1.358V34.08a1.341 1.341 0 00-1.32-1.358z"
					fill="#e6e6e6"
				/>
				<path
					fill="#fff"
					d="M16.499 10.394h54.116v3.08H16.499zM16.609 16.884h54.116v1.43H16.609zM16.609 19.413h54.116v1.43H16.609zM16.609 21.943h54.116v1.43H16.609zM48.652 66.27h24.9a.8.8 0 00.567-1.369L58.18 48.962a.8.8 0 00-1.134 0l-3.964 3.964a1.681 1.681 0 01-2.378 0L38.289 40.511a.8.8 0 00-1.134 0l-24.28 24.28a.8.8 0 00.567 1.369h35.237z"
				/>
				<circle cx={3.08} cy={3.08} r={3.08} transform="translate(15.949 36.902)" fill="#fff" />
				<path
					d="M9.349 73.419a1.341 1.341 0 00-1.32 1.358v30.777a1.341 1.341 0 001.32 1.358h68.415a1.341 1.341 0 001.32-1.358V74.777a1.341 1.341 0 00-1.32-1.358z"
					fill="#e6e6e6"
				/>
				<path
					d="M48.652 106.966h24.9a.8.8 0 00.567-1.369L58.18 89.658a.8.8 0 00-1.134 0l-3.964 3.964a1.681 1.681 0 01-2.378 0L38.289 81.207a.8.8 0 00-1.134 0l-24.28 24.28a.8.8 0 00.567 1.369h35.237z"
					fill="#fff"
				/>
				<circle cx={3.08} cy={3.08} r={3.08} transform="translate(15.949 77.599)" fill="#fff" />
				<path
					d="M31.458 25.298a1.982 1.982 0 00-1.98 1.98v109.991a1.982 1.982 0 001.98 1.98h81.394a1.982 1.982 0 001.98-1.98V27.278a1.982 1.982 0 00-1.98-1.98z"
					fill="#e6e6e6"
				/>
				<path
					d="M33.862 28.378a2 2 0 00-1.964 2.027v104.618a2 2 0 001.964 2.027h76.365a2 2 0 001.964-2.027V30.405a2 2 0 00-1.964-2.027z"
					fill="#fff"
				/>
				<path
					d="M45.811 43.597a.881.881 0 00-.88.88v4.037a.881.881 0 00.88.88h51.916a.881.881 0 00.88-.88v-4.039a.881.881 0 00-.88-.88z"
					fill={fill}
				/>
				<path
					d="M45.481 56.962a.22.22 0 00-.22.22v1.577a.22.22 0 00.22.22h52.8a.22.22 0 00.22-.22v-1.577a.22.22 0 00-.22-.22zM45.481 62.087a.22.22 0 00-.22.22v1.577a.22.22 0 00.22.22h52.8a.22.22 0 00.22-.22v-1.577a.22.22 0 00-.22-.22zM45.481 67.211a.22.22 0 00-.22.22v1.577a.22.22 0 00.22.22h52.8a.22.22 0 00.22-.22v-1.577a.22.22 0 00-.22-.22zM45.371 77.447a.22.22 0 00-.22.22v1.577a.22.22 0 00.22.22h52.8a.22.22 0 00.22-.22v-1.577a.22.22 0 00-.22-.22zM45.371 82.572a.22.22 0 00-.22.22v1.577a.22.22 0 00.22.22h52.8a.22.22 0 00.22-.22v-1.577a.22.22 0 00-.22-.22zM45.371 87.696a.22.22 0 00-.22.22v1.577a.22.22 0 00.22.22h52.8a.22.22 0 00.22-.22v-1.577a.22.22 0 00-.22-.22zM45.371 92.847a.22.22 0 00-.22.22v1.577a.22.22 0 00.22.22h52.8a.22.22 0 00.22-.22v-1.577a.22.22 0 00-.22-.22zM45.371 97.97a.22.22 0 00-.22.22v1.577a.22.22 0 00.22.22h52.8a.22.22 0 00.22-.22V98.19a.22.22 0 00-.22-.22zM45.371 103.095a.22.22 0 00-.22.22v1.577a.22.22 0 00.22.22h52.8a.22.22 0 00.22-.22v-1.577a.22.22 0 00-.22-.22zM45.371 108.245a.22.22 0 00-.22.22v1.577a.22.22 0 00.22.22h52.8a.22.22 0 00.22-.22v-1.577a.22.22 0 00-.22-.22zM45.371 113.369a.22.22 0 00-.22.22v1.577a.22.22 0 00.22.22h52.8a.22.22 0 00.22-.22v-1.577a.22.22 0 00-.22-.22zM45.371 118.494a.22.22 0 00-.22.22v1.577a.22.22 0 00.22.22h52.8a.22.22 0 00.22-.22v-1.577a.22.22 0 00-.22-.22z"
					fill="#ccc"
				/>
			</g>
		</svg>
	)
}

/*
|--------------------------------------------------------------------------
| EXPORT
|--------------------------------------------------------------------------
*/
const DocumentSVG = React.memo(Document)
export { DocumentSVG }
