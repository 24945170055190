import * as React from 'react'
import Image from 'next/image'
import platform from '../../../../.tmp/platform'

const StartonWhiteLabelLogo = () => {
	return platform?.frontSettings?.logo?.small ? (
		<Image src={platform?.frontSettings?.logo?.small} width={'55px'} height={'55px'} />
	) : null
}

export { StartonWhiteLabelLogo }
