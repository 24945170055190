import * as React from 'react'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { IStartonButtonProps, StartonButton } from 'components/Core/StartonButton/StartonButton'

export interface IStartonConfirmationDialogProps {
	setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>
}
interface IStartonConfirmationDialogOptions {
	title?: string
	description?: React.ReactNode
	confirmationText?: string
	cancellationText?: string
}
interface IStartonConfirmationDialogInternalProps {
	onConfirm: (...args: any) => void
	onClose?: () => void
	onCancel?: () => void
	dialogProps?: DialogProps
	cancellationButtonProps?: IStartonButtonProps
	confirmationButtonProps?: IStartonButtonProps
	children: React.ReactElement<IStartonConfirmationDialogProps>
	options: IStartonConfirmationDialogOptions
}

const StartonConfirmationDialog: React.FC<IStartonConfirmationDialogInternalProps> = (
	props: IStartonConfirmationDialogInternalProps,
) => {
	const [openDialog, setOpenDialog] = React.useState<boolean>(false)

	const handleClose = React.useCallback(() => {
		props.onClose && props.onClose()
		setOpenDialog(false)
	}, [props])

	const handleCancel = React.useCallback(() => {
		props.onCancel && props.onCancel()
		handleClose()
	}, [handleClose, props])

	const handleConfirm = React.useCallback(
		(...args) => {
			props.onConfirm && props.onConfirm(...args)
			handleClose()
		},
		[handleClose, props],
	)

	const { t } = useTranslation()

	const defaultOptions: IStartonConfirmationDialogOptions = {
		title: t('tools.are_you_sure'),
		confirmationText: t('tools.validate'),
		cancellationText: t('tools.refuse'),
	}
	return (
		<React.Fragment>
			{React.cloneElement(props.children, { setOpenDialog })}
			<Dialog open={openDialog} onClose={handleCancel} {...props.dialogProps}>
				<DialogTitle>{defaultOptions.title || props.options.title}</DialogTitle>
				<DialogContent>
					<DialogContentText>{props.options.description}</DialogContentText>
				</DialogContent>
				<DialogActions className="flex flex-row justify-between">
					<StartonButton className="w-1/2" onClick={handleCancel} {...props.cancellationButtonProps}>
						{defaultOptions.cancellationText || props.options.cancellationText}
					</StartonButton>
					<StartonButton className="w-1/2" onClick={handleConfirm} {...props.confirmationButtonProps}>
						{defaultOptions.confirmationText || props.options.confirmationText}
					</StartonButton>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	)
}

export { StartonConfirmationDialog }
