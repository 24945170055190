import { DeepPartial } from 'redux'
import { UserEntity } from 'model/userEntity'

/*
|--------------------------------------------------------------------------
| STORE CONTRACTS
|--------------------------------------------------------------------------
*/

// CONSTANTS
// ----------------------------------------------------------------------------
export const COOKIE_TOKEN_KEY = 'token'

// State interfaces
// ----------------------------------------------------------------------------
export interface IUserState {
	isAuthenticated: boolean
	loading: boolean
	user: UserEntity | null
}

// Actions constants
// ----------------------------------------------------------------------------
export const SET_USER = '[USER] SET'
export const UPDATE_USER = '[USER] UPDATE'
export const LOGOUT_USER = '[USER] LOGOUT'

// Actions interfaces
// ----------------------------------------------------------------------------
export interface ISetUserAction {
	type: typeof SET_USER
	payload: User
}
export interface IUpdateUserAction {
	type: typeof UPDATE_USER
	payload: DeepPartial<User>
}
export interface ILogoutUserAction {
	type: typeof LOGOUT_USER
}

/*
|--------------------------------------------------------------------------
| Actions type
|--------------------------------------------------------------------------
*/
export type UserActionType = ISetUserAction | IUpdateUserAction | ILogoutUserAction
