import * as React from 'react'
import { makeStyles } from '@material-ui/styles'
import { IconButton, IconButtonProps, Theme } from '@material-ui/core'
import clsx from 'clsx'
import { LinkProps, StartonLink } from 'components/Core/StartonLink/StartonLink'
import { NAV_HEIGHT } from 'components/Core/StartonNav/StartonNav'

/*
|--------------------------------------------------------------------------
| CONTRACTS
|--------------------------------------------------------------------------
*/
export interface IStartonNavIconProps {
	type: 'link' | 'button'
	children: React.ReactNode
	linkProps?: Omit<LinkProps, 'children'>
	buttonProps?: Omit<IconButtonProps, 'children'>
}
type StyleProps = Record<string, string>
type StyleClassKey = 'navLinkContainer'
type PropClasses = Record<StyleClassKey, string>

/*
|--------------------------------------------------------------------------
| STYLES
|--------------------------------------------------------------------------
*/
const useStyles = makeStyles<Theme, StyleProps, StyleClassKey>((theme) => ({
	navLinkContainer: {
		height: NAV_HEIGHT,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		padding: 0,
		'&:hover': {
			backgroundColor: 'transparent',
			'& svg': {
				fill: theme.palette.primary.main,
				stroke: theme.palette.primary.main,
			},
		},
	},
}))

/*
|--------------------------------------------------------------------------
| COMPONENT
|--------------------------------------------------------------------------
*/
const StartonNavIcon: React.FC<IStartonNavIconProps> = (props: IStartonNavIconProps) => {
	const classes: PropClasses = useStyles({} as StyleProps)

	// If type is link and linkProps is defined
	// ----------------------------------------------------------------------------
	if (props.type === 'link' && props.linkProps) {
		return (
			<StartonLink {...props.linkProps} className={clsx(classes.navLinkContainer, props.linkProps.className)}>
				{props.children}
			</StartonLink>
		)
	}

	// If type is button and buttonProps is defined
	// ----------------------------------------------------------------------------
	if (props.type === 'button' && props.buttonProps) {
		return (
			<IconButton
				{...props.buttonProps}
				className={clsx(classes.navLinkContainer, props.buttonProps.className)}
				disableFocusRipple
				disableRipple
			>
				{props.children}
			</IconButton>
		)
	}

	// If type is not known or props is not defined
	// ----------------------------------------------------------------------------
	return null
}

/*
|--------------------------------------------------------------------------
| DEFAULT PROPS
|--------------------------------------------------------------------------
*/
StartonNavIcon.defaultProps = {
	type: 'link',
}

/*
|--------------------------------------------------------------------------
| EXPORT
|--------------------------------------------------------------------------
*/
export { StartonNavIcon }
