import * as React from 'react'
import { Box, FormControl, InputLabel, MenuItem, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import * as Yup from 'yup'
import { Field, Form, Formik } from 'formik'
import { FormikHelpers } from 'formik/dist/types'
import { Select, TextField } from 'formik-material-ui'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { mutate } from 'swr'
import platform from '../../../../.tmp/platform'
import { StartonButton } from 'components/Core'
import starton from 'data/axios'
import { useUser } from 'stores/user/selectors'
import { WalletEntity } from 'model/walletEntity'

/*
|--------------------------------------------------------------------------
| INTERFACES
|--------------------------------------------------------------------------
*/
interface IForm {
	address: string
	type: string
}
export interface IStartonWalletFormProps {
	handleCloseModal: () => void
}
type StyleProps = IStartonWalletFormProps
type StyleClassKey = 'buttonContainer' | 'modalAction'
type PropClasses = Record<StyleClassKey, string>

/*
|--------------------------------------------------------------------------
| STYLES
|--------------------------------------------------------------------------
*/
const useStyles = makeStyles<Theme, StyleProps, StyleClassKey>((theme) => ({
	buttonContainer: {
		marginBottom: theme.spacing(2),
	},
	modalAction: {
		width: '100%',
		// padding: theme.spacing(3, 4, 0),
	},
}))

/*
|--------------------------------------------------------------------------
| COMPONENT
|--------------------------------------------------------------------------
*/
const StartonWalletForm: React.FC<IStartonWalletFormProps> = (props: IStartonWalletFormProps) => {
	const classes: PropClasses = useStyles({} as StyleProps)
	const { t } = useTranslation()
	const { user } = useUser()

	const schemaValidations = Yup.object().shape({
		type: Yup.string().required('Field required'),
		address: Yup.string().when('type', {
			is: 'external',
			then: Yup.string()
				.matches(/^0x[a-fA-F0-9]{40}$/, 'Please enter a valid ethereum address')
				.required('Field required'),
		}),
	})

	// METHODS
	// ----------------------------------------------------------------------------
	const handleSubmit = async (values: IForm, formikHelpers: FormikHelpers<IForm>) => {
		formikHelpers.setSubmitting(true)
		starton
			.post(`/wallet`, {
				owner: user?.id,
				type: values.type,
				address: values.type === WalletEntity.TypeEnum.External ? values.address : null,
			})
			.then(() => {
				formikHelpers.setSubmitting(false)
				mutate(`/wallet?owner=${user?.id}`)
				props.handleCloseModal()
			})
			.catch(() => {
				formikHelpers.setSubmitting(false)
			})
	}

	// RENDER
	// ----------------------------------------------------------------------------
	return (
		<Formik
			initialValues={{
				type: 'external',
				address: '',
			}}
			onSubmit={handleSubmit}
			validationSchema={schemaValidations}
		>
			{(formikProps) => (
				<Form>
					{platform.settings.wallet?.custodian === true && (
						<FormControl variant="outlined" className={classes.buttonContainer} fullWidth>
							<InputLabel htmlFor="country">{t('wallet.type')}</InputLabel>
							<Field
								component={Select}
								name="type"
								variant="outlined"
								label="Type"
								inputProps={{
									label: t('wallet.type'),
									labelId: 'type',
									id: 'type',
								}}
							>
								<MenuItem key="external" value={WalletEntity.TypeEnum.External}>
									External - Your own blockchain address
								</MenuItem>
								<MenuItem key="internal" value={WalletEntity.TypeEnum.Kms}>
									Internal - We handle the wallet for you
								</MenuItem>
							</Field>
						</FormControl>
					)}

					{formikProps.values.type === WalletEntity.TypeEnum.External && (
						<Field
							component={TextField}
							id="address"
							name="address"
							label="Address"
							variant="outlined"
							className={classes.buttonContainer}
							placeholder={t('wallet.add_user_address')}
							InputLabelProps={{
								shrink: true,
							}}
							fullWidth
						/>
					)}

					<Box className="flex flex-col md:flex-row md:justify-between items-center">
						<StartonButton
							fullWidth
							type="submit"
							className={clsx(classes.modalAction, 'order-1 md:order-2 md:ml-2')}
							disabled={!formikProps.isValid}
							loading={formikProps.isSubmitting}
						>
							{t('wallet.add_user_address')}
						</StartonButton>
					</Box>
				</Form>
			)}
		</Formik>
	)
}

/*
|--------------------------------------------------------------------------
| EXPORT
|--------------------------------------------------------------------------
*/
export { StartonWalletForm }
