import { useSelector } from 'react-redux'
import { AppState } from 'stores'
import { IUserState } from 'stores/user/contracts'

// GET ORGANISATION
// ----------------------------------------------------------------------------
export const getUser = (store: AppState) => store.user
export function useUser() {
	return useSelector<AppState, IUserState>(getUser)
}
