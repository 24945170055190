import * as React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Typography, TypographyProps, Theme } from '@material-ui/core'
import clsx from 'clsx'

/*
|--------------------------------------------------------------------------
| CONTRACTS
|--------------------------------------------------------------------------
*/
export interface IStartonTitleProps extends TypographyProps {
	children: React.ReactNode
	className?: string
	size?: TitleSize
}
type StyleProps = IStartonTitleProps
type StyleClassKey = 'titleContainer' | 'mainTitle' | 'fallbackTitle'
type PropClasses = Record<StyleClassKey, string>
type TitleSize = 'default' | 'big' | 'small' | 'very-small'

/*
|--------------------------------------------------------------------------
| STYLES
|--------------------------------------------------------------------------
*/
const useStyles = makeStyles<Theme, StyleProps, StyleClassKey>((theme) => ({
	titleContainer: {
		width: '100%',
		position: 'relative',
		minHeight: 64,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	mainTitle: {
		position: 'absolute',
		textAlign: 'center',
		width: '100%',
		letterSpacing: 3.6,
		textTransform: 'uppercase',
		fontWeight: 800,
		fontSize: (props: IStartonTitleProps) => {
			switch (props.size) {
				case 'big':
					return '1.9rem'
				case 'small':
					return '1.1rem'
				case 'very-small':
					return '1rem'
				default:
					return '1.5rem'
			}
		},
		[theme.breakpoints.only('xs')]: {
			fontSize: (props: IStartonTitleProps) => {
				switch (props.size) {
					case 'big':
						return '1.6rem'
					case 'small':
						return '0.9rem'
					case 'very-small':
						return '0.75rem'
					default:
						return '1.4rem'
				}
			},
		},
	},
	fallbackTitle: {
		position: 'absolute',
		textAlign: 'center',
		width: '100%',
		fontWeight: 800,
		letterSpacing: 7.92,
		textTransform: 'uppercase',
		opacity: 0.05,
		fontSize: (props: IStartonTitleProps) => {
			switch (props.size) {
				case 'big':
					return '3.2rem'
				case 'small':
					return '1.6rem'
				case 'very-small':
					return '1.2rem'
				default:
					return '2.8rem'
			}
		},
		[theme.breakpoints.only('xs')]: {
			fontSize: (props: IStartonTitleProps) => {
				switch (props.size) {
					case 'big':
						return '2.4rem'
					case 'small':
						return '1.4rem'
					case 'very-small':
						return '1.0rem'
					default:
						return '2.1rem'
				}
			},
		},
	},
}))

/*
|--------------------------------------------------------------------------
| COMPONENT
|--------------------------------------------------------------------------
*/
const StartonTitle: React.FC<IStartonTitleProps> = (props: IStartonTitleProps) => {
	const classes: PropClasses = useStyles(props as StyleProps)
	const { children, ...restProps } = props

	return (
		<div className={clsx(classes.titleContainer, props.classes, props.className)}>
			<Typography {...restProps} className={classes.fallbackTitle}>
				{children}
			</Typography>
			<Typography {...restProps} className={classes.mainTitle}>
				{children}
			</Typography>
		</div>
	)
}

/*
|--------------------------------------------------------------------------
| DEFAULT PROPS
|--------------------------------------------------------------------------
*/
StartonTitle.defaultProps = {
	size: 'default',
}

/*
|--------------------------------------------------------------------------
| EXPORT
|--------------------------------------------------------------------------
*/
export { StartonTitle }
