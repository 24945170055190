import { TICK, TickActionType, ITickState } from './contracts'

/*
|--------------------------------------------------------------------------
| INITIAL STATE
|--------------------------------------------------------------------------
*/
const tickInitialState: ITickState = {
	lastUpdate: 0,
}

/*
|--------------------------------------------------------------------------
| REDUCER
|--------------------------------------------------------------------------
*/
export default function reducer(state: ITickState = tickInitialState, action: TickActionType): ITickState {
	switch (action.type) {
		case TICK:
			return Object.assign({}, state, {
				lastUpdate: action.ts,
			})
		default:
			return state
	}
}
