import { Action } from 'redux'
import { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack'

/*
|--------------------------------------------------------------------------
| STORE CONTRACTS
|--------------------------------------------------------------------------
*/

// State interfaces
// ----------------------------------------------------------------------------
export type RouteType = 'public' | 'guest' | 'private'
export interface ISnackbar {
	message: SnackbarMessage
	key?: number | string
	options?: OptionsObject
	dismissed?: boolean
}
export interface IPublicRoute {
	pathname: string
	type: RouteType
}
export interface ISettingsState {
	routes: Array<IPublicRoute>
	snackbars: Array<ISnackbar>
}

// Actions constants
// ----------------------------------------------------------------------------
export const SET_SETTINGS = '[SETTINGS] Set settings'
export const ENQUEUE_SNACKBAR = '[SETTINGS] Add snackbar on enqueue'
export const CLOSE_SNACKBAR = '[SETTINGS] Close snackbar'
export const REMOVE_SNACKBAR = '[SETTINGS] Remove snackbar'

// Actions interfaces
// ----------------------------------------------------------------------------
export interface ISetSettingsActions extends Action {
	type: typeof SET_SETTINGS
	payload: ISettingsState
}
export interface IEnqueueSnackbarActions extends Action {
	type: typeof ENQUEUE_SNACKBAR
	payload: ISnackbar
}
export interface ICloseSnackbarActions extends Action {
	type: typeof CLOSE_SNACKBAR
	dismissAll: boolean
	payload: SnackbarKey
}
export interface IRemoveSnackbarActions extends Action {
	type: typeof REMOVE_SNACKBAR
	payload: SnackbarKey
}

/*
|--------------------------------------------------------------------------
| Actions type
|--------------------------------------------------------------------------
*/
export type SettingsActionType =
	| ISetSettingsActions
	| IEnqueueSnackbarActions
	| ICloseSnackbarActions
	| IRemoveSnackbarActions
