import * as React from 'react'
import { Theme, useTheme } from '@material-ui/core'
import { ISVGProps } from 'components/SVG/contracts'

/*
|--------------------------------------------------------------------------
| CONTRACTS
|--------------------------------------------------------------------------
*/
type IWalletProps = ISVGProps

/*
|--------------------------------------------------------------------------
| COMPONENT
|--------------------------------------------------------------------------
*/
const Wallet: React.FC<IWalletProps> = (props: IWalletProps) => {
	const theme: Theme = useTheme()
	const fill: string = React.useMemo<string>(() => props.fill || theme.palette.text.primary, [props])

	return (
		<svg width={50} height={50} viewBox="0 0 50 50" {...props} fill={fill}>
			<g transform="translate(-1725 -30)">
				<circle cx={25} cy={25} r={25} transform="translate(1725 30)" fill="none" stroke="none" />
				<path
					stroke="none"
					d="M1760.42 48.5h-19.357a.813.813 0 010-1.625h19.5a.812.812 0 00.813-.812 2.437 2.437 0 00-2.438-2.438h-18.688a3.25 3.25 0 00-3.25 3.25v16.25a3.25 3.25 0 003.25 3.25h20.17a2.514 2.514 0 002.58-2.437v-13a2.514 2.514 0 00-2.58-2.438zm-2.3 10.563a1.625 1.625 0 111.625-1.625 1.625 1.625 0 01-1.62 1.625z"
				/>
			</g>
		</svg>
	)
}

/*
|--------------------------------------------------------------------------
| EXPORT
|--------------------------------------------------------------------------
*/
const WalletSVG = React.memo(Wallet)
export { WalletSVG }
