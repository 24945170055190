import { IUserState, LOGOUT_USER, SET_USER, UPDATE_USER, UserActionType } from './contracts'

/*
|--------------------------------------------------------------------------
| INITIAL STATE
|--------------------------------------------------------------------------
*/
const userInitialState: IUserState = {
	isAuthenticated: false,
	loading: true,
	user: null,
}

/*
|--------------------------------------------------------------------------
| REDUCER
|--------------------------------------------------------------------------
*/
export default function reducer(state: IUserState = userInitialState, action: UserActionType): IUserState {
	switch (action.type) {
		case SET_USER:
			return {
				...state,
				isAuthenticated: !!action.payload,
				loading: false,
				user: action.payload,
			}
		case UPDATE_USER:
			return {
				...state,
				isAuthenticated: !!action.payload,
				user: Object.assign({}, state.user, action.payload),
			}
		case LOGOUT_USER:
			return {
				...state,
				loading: false,
				isAuthenticated: false,
				user: null,
			}
		default:
			return state
	}
}
