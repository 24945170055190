import * as React from 'react'
import { useRouter } from 'next/router'
import { CircularProgress, Typography } from '@material-ui/core'
import Cookies from 'js-cookie'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useSettings } from 'stores/settings/selectors'
import { RouteType } from 'stores/settings'
import { useUser } from 'stores/user/selectors'
import { COOKIE_TOKEN_KEY, logoutUser, setUser } from 'stores/user'
import starton from 'data/axios'

/*
|--------------------------------------------------------------------------
| CONTRACTS
|--------------------------------------------------------------------------
*/
export interface IStartonProtectedRoutesProps {
	children: React.ReactNode
}

/*
|--------------------------------------------------------------------------
| COMPONENT
|--------------------------------------------------------------------------
*/
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const StartonProtectedRoutes: React.FC<IStartonProtectedRoutesProps> = ({ children }: IStartonProtectedRoutesProps) => {
	const [routeType, setRouteType] = React.useState<RouteType>('private')
	const [isReady, setIsReady] = React.useState<boolean>(false)
	const { isAuthenticated, user, loading } = useUser()
	const settings = useSettings()
	const router = useRouter()
	const dispatch = useDispatch()

	// Get user token for cookies
	// ----------------------------------------------------------------------------
	React.useEffect(() => {
		async function loadUserTokenFromCookie() {
			const token = Cookies.get(COOKIE_TOKEN_KEY)
			if (token) {
				try {
					const userData = await starton.get('/user/me')
					dispatch(setUser(userData.data))
					setIsReady(true)
				} catch (e) {
					dispatch(logoutUser())
					setIsReady(true)
				}
			} else {
				dispatch(logoutUser())
				setIsReady(true)
			}
		}
		loadUserTokenFromCookie()
	}, [])

	// Handle route change callback
	// ----------------------------------------------------------------------------
	const handleRouteChange = React.useCallback(
		(url) => {
			const filteredRoute = settings.routes.filter((route) => route.pathname === url.split('?')[0])
			const isFound = filteredRoute.length > 0 && filteredRoute[0]
			if (isFound) {
				const route = filteredRoute[0]

				// Define route type
				setRouteType(route.type)
				if (route.type === 'private' && !user) {
					router.replace({
						pathname: '/login',
					})
				}
			} else {
				// If current route isn't found and user is not connected, redirect to login
				if (!isAuthenticated) {
					setRouteType('private')
					url = url.replace('/', '')
					url && url !== '404'
						? router.replace({
								pathname: '/login',
								search: `?redirect_url=${url}`,
						  })
						: router.replace({
								pathname: '/login',
						  })
				}
			}
		},
		[settings, isAuthenticated, user],
	)

	// Route guard
	// ----------------------------------------------------------------------------
	React.useEffect(() => {
		if (isReady) {
			// Check that initial route is OK
			handleRouteChange(router.asPath)

			// Monitor routes
			router.events.on('routeChangeComplete', handleRouteChange)
		}

		return () => {
			router.events.off('routeChangeComplete', handleRouteChange)
		}
	}, [user, isReady])

	const { t } = useTranslation()

	// If loading is true and user is not authenticated
	// ----------------------------------------------------------------------------
	if (loading || (!isAuthenticated && routeType === 'private')) {
		return (
			<div className="w-screen h-screen flex flex-col justify-center items-center">
				<CircularProgress />
				<Typography className="mt-6">{t('wallet.loading')}</Typography>
			</div>
		)
	}

	// If loading is false and user is authenticated
	// ----------------------------------------------------------------------------
	return children
}

/*
|--------------------------------------------------------------------------
| EXPORTER
|--------------------------------------------------------------------------
*/
export { StartonProtectedRoutes }
