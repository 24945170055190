import * as React from 'react'
import clsx from 'clsx'
import { useRouter } from 'next/router'
import Link, { LinkProps as NextLinkProps } from 'next/link'
import MuiLink, { LinkProps as MuiLinkProps } from '@material-ui/core/Link'

type NextComposedProps = Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href'> & NextLinkProps

// eslint-disable-next-line react/display-name
const NextComposed = React.forwardRef<HTMLAnchorElement, NextComposedProps>((props, ref) => {
	const { as, href, replace, scroll, passHref, shallow, prefetch, ...other } = props

	return (
		<Link
			href={href}
			prefetch={prefetch}
			as={as}
			replace={replace}
			scroll={scroll}
			shallow={shallow}
			passHref={passHref}
		>
			{/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
			<a ref={ref} {...other} />
		</Link>
	)
})

interface LinkPropsBase {
	activeClassName?: string
	innerRef?: React.Ref<HTMLAnchorElement>
	naked?: boolean
}

export type LinkProps = LinkPropsBase & NextComposedProps & Omit<MuiLinkProps, 'href'>

// A styled version of the Next.js StartonLink component:
// https://nextjs.org/docs/#with-link
function InnerLink(props: LinkProps) {
	const { href, activeClassName = 'active', className: classNameProps, innerRef, naked, ...other } = props

	const router = useRouter()
	const pathname = typeof href === 'string' ? href : href.pathname
	const className = clsx(classNameProps, {
		[activeClassName]: router.pathname === pathname && activeClassName,
	})

	if (naked) {
		return <NextComposed className={className} ref={innerRef} href={href} {...other} />
	}

	return (
		<MuiLink
			underline="none"
			component={NextComposed}
			className={className}
			ref={innerRef}
			href={href as string}
			{...other}
		/>
	)
}
// eslint-disable-next-line react/display-name
const StartonLink = React.forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => (
	<InnerLink {...props} innerRef={ref} />
))

export { StartonLink }
