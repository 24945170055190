import {
	CLOSE_SNACKBAR,
	ENQUEUE_SNACKBAR,
	ISettingsState,
	REMOVE_SNACKBAR,
	SET_SETTINGS,
	SettingsActionType,
} from './contracts'

/*
|--------------------------------------------------------------------------
| INITIAL STATE
|--------------------------------------------------------------------------
*/
const tickInitialState: ISettingsState = {
	routes: [
		{
			pathname: '/login',
			type: 'guest',
		},
		{
			pathname: '/en/login',
			type: 'guest',
		},
		{
			pathname: '/fr/login',
			type: 'guest',
		},
		{
			pathname: '/register',
			type: 'guest',
		},
		{
			pathname: '/fr/register',
			type: 'guest',
		},
		{
			pathname: '/en/register',
			type: 'guest',
		},
		{
			pathname: '/forgot-password',
			type: 'guest',
		},
		{
			pathname: '/fr/forgot-password',
			type: 'guest',
		},
		{
			pathname: '/en/forgot-password',
			type: 'guest',
		},
		{
			pathname: '/reset-password',
			type: 'guest',
		},
		{
			pathname: '/fr/reset-password',
			type: 'guest',
		},
		{
			pathname: '/en/reset-password',
			type: 'guest',
		},
		{
			pathname: '/waiting-confirmation',
			type: 'guest',
		},
		{
			pathname: '/fr/waiting-confirmation',
			type: 'guest',
		},
		{
			pathname: '/en/waiting-confirmation',
			type: 'guest',
		},
	],
	snackbars: [],
}

/*
|--------------------------------------------------------------------------
| REDUCER
|--------------------------------------------------------------------------
*/
export default function reducer(state: ISettingsState = tickInitialState, action: SettingsActionType): ISettingsState {
	switch (action.type) {
		case SET_SETTINGS:
			return Object.assign({}, state, action.payload)
		case ENQUEUE_SNACKBAR:
			return {
				...state,
				snackbars: [...state.snackbars, action.payload],
			}
		case CLOSE_SNACKBAR:
			return {
				...state,
				snackbars: state.snackbars.map((snackbar) =>
					action.dismissAll || snackbar.key === action.payload
						? { ...snackbar, dismissed: true }
						: { ...snackbar },
				),
			}
		case REMOVE_SNACKBAR:
			return {
				...state,
				snackbars: state.snackbars.filter((snackbar) => snackbar.key !== action.payload),
			}
		default:
			return state
	}
}
