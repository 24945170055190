import axios from 'axios';
import Cookies from "js-cookie";
import {COOKIE_TOKEN_KEY} from "stores/user";
const token = Cookies.get(COOKIE_TOKEN_KEY)

const starton = axios.create({
	baseURL: process.env.NEXT_PUBLIC_API_URL
});
if (token) {
	starton.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

export default starton;

