import { DeepPartial } from 'redux'
import {
	ILogoutUserAction,
	ISetUserAction,
	IUpdateUserAction,
	LOGOUT_USER,
	SET_USER,
	UPDATE_USER,
} from 'stores/user/contracts'
import { UserEntity } from 'model/userEntity'

// Set user
// ----------------------------------------------------------------------------
export const setUser = (data: UserEntity): ISetUserAction => {
	return {
		type: SET_USER,
		payload: data,
	}
}

// Update user
// ----------------------------------------------------------------------------
export const updateUser = (data: DeepPartial<UserEntity>): IUpdateUserAction => {
	return {
		type: UPDATE_USER,
		payload: data,
	}
}

// Logout user
// ----------------------------------------------------------------------------
export const logoutUser = (): ILogoutUserAction => {
	return {
		type: LOGOUT_USER,
	}
}
