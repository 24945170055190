import * as React from 'react'
import { IStartonAssetGradient } from 'StartonTokenCard.tsx'

/*
|--------------------------------------------------------------------------
| CONTRACTS
|--------------------------------------------------------------------------
*/
interface IStartonAssetProps extends IStartonAssetGradient {
	name: string
	className?: string
	style?: React.CSSProperties
}

/*
|--------------------------------------------------------------------------
| COMPONENT
|--------------------------------------------------------------------------
*/
/* eslint-disable react/display-name */
const StartonCurrencySVG: React.ForwardRefExoticComponent<IStartonAssetProps & React.RefAttributes<SVGSVGElement>> =
	React.forwardRef<SVGSVGElement, IStartonAssetProps>((props: IStartonAssetProps, remoteRef) => {
		return (
			<svg
				className={props.className}
				width="415"
				height="235"
				viewBox="0 0 415 235"
				style={props.style}
				ref={remoteRef}
			>
				<defs>
					<clipPath id={`${props.name}-clip-path`}>
						<rect id={`${props.name}-CardBackground`} width="415" height="235" rx="14" fill="#fff" />
					</clipPath>
					<linearGradient
						id={`${props.name}-linear-gradient`}
						x1="1"
						y1="0.111"
						x2="0.056"
						y2="1"
						gradientUnits="objectBoundingBox"
					>
						<stop offset="0" stopColor={props.endColor} />
						<stop offset="1" stopColor={props.startColor} />
					</linearGradient>
					<filter
						id={`${props.name}-Tracé_17`}
						x="-60.616"
						y="-45.228"
						width="519.212"
						height="216.126"
						filterUnits="userSpaceOnUse"
					>
						<feOffset dy="6" />
						<feGaussianBlur stdDeviation="12.375" result="blur" />
						<feFlood floodColor="#2e26ed" floodOpacity="0.251" />
						<feComposite operator="in" in2="blur" />
						<feComposite in="SourceGraphic" />
					</filter>
				</defs>
				<g id={`${props.name}-Gradient`} clipPath={`url(#${props.name}-clip-path)`}>
					<path
						id={`${props.name}-Tracé_15`}
						d="M5190.322,17.5s93.4,54.631,229.582-25.014,212.567-13.375,212.567-13.375V-106.1H5187.509Z"
						transform="translate(-5211 102)"
						opacity="0.1"
						fill={`url(#${props.name}-linear-gradient)`}
					/>
					<g transform="matrix(1, 0, 0, 1, 0, 0)" filter={`url(#${props.name}-Tracé_17)`}>
						<path
							id={`${props.name}-Tracé_17-2`}
							d="M5190.322,17.5s93.4,54.631,229.582-25.014,212.567-13.375,212.567-13.375V-106.1H5187.509Z"
							transform="translate(-5211 92)"
							fill={`url(#${props.name}-linear-gradient)`}
						/>
					</g>
				</g>
			</svg>
		)
	})

/*
|--------------------------------------------------------------------------
| EXPORT
|--------------------------------------------------------------------------
*/
export { StartonCurrencySVG }
