import { useDispatch, useSelector } from 'react-redux'
import { SnackbarKey } from 'notistack'
import { AppState } from 'stores'
import { ISettingsState, ISnackbar } from 'stores/settings/contracts'
import { closeSnackbar, enqueueSnackbar, removeSnackbar } from 'stores/settings/actions'

// GET SETTINGS
// ----------------------------------------------------------------------------
export const getSettings = (store: AppState) => store.settings
export function useSettings() {
	return useSelector<AppState, ISettingsState>(getSettings)
}

// GET SNACKBARS
// ----------------------------------------------------------------------------
export const getSnack = (store: AppState) => store.settings.snackbars
export function useSnack() {
	const snackbars = useSelector<AppState, Array<ISnackbar>>(getSnack)
	const dispatch = useDispatch()

	// Methods
	// ----------------------------------------------------------------------------
	const enqueueSnack = (payload: ISnackbar) => {
		dispatch(enqueueSnackbar(payload))
	}

	const closeSnack = (payload: SnackbarKey) => {
		dispatch(closeSnackbar(payload))
	}

	const removeSnack = (payload: SnackbarKey) => {
		dispatch(removeSnackbar(payload))
	}

	// Return
	// ----------------------------------------------------------------------------
	return {
		snackbars,
		enqueueSnack,
		closeSnack,
		removeSnack,
	}
}
