import * as React from 'react'
import { Box, CircularProgress, IconButton, InputAdornment, TextField, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import LaunchIcon from '@material-ui/icons/Launch'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useRouter } from 'next/router'
import { useSnack } from 'stores/settings/selectors'
import starton from 'data/axios'
import { CurrencyEntity } from 'model/currencyEntity'

/*
|--------------------------------------------------------------------------
| INTERFACES
|--------------------------------------------------------------------------
*/
export interface ISAMDepositLemonwayBankProps {
	currency: CurrencyEntity
	handleCloseModal: () => void
}
type StyleProps = ISAMDepositLemonwayBankProps
type StyleClassKey = 'hint' | 'buttonContainer' | 'buttonHelp' | 'modalAction'
type PropClasses = Record<StyleClassKey, string>

/*
|--------------------------------------------------------------------------
| STYLES
|--------------------------------------------------------------------------
*/
const useStyles = makeStyles<Theme, StyleProps, StyleClassKey>((theme) => ({
	hint: {
		marginBottom: theme.spacing(2),
	},
	buttonContainer: {
		marginBottom: theme.spacing(2),
	},
	buttonHelp: {
		color: theme.palette.text.hint,
	},
	modalAction: {
		width: '100%',
		// padding: theme.spacing(3, 4, 0),
	},
}))

/*
|--------------------------------------------------------------------------
| COMPONENT
|--------------------------------------------------------------------------
*/

const SAMDepositLemonwayBank: React.FC<ISAMDepositLemonwayBankProps> = (_: ISAMDepositLemonwayBankProps) => {
	const classes: PropClasses = useStyles({} as StyleProps)
	const addressRef: React.MutableRefObject<any> = React.useRef()
	const router = useRouter()
	const { enqueueSnack } = useSnack()
	const [ibanData, setIbanData] = React.useState({
		iban: '',
		bic: '',
		holder: '',
		swift: '',
	})

	React.useEffect(() => {
		starton
			.get(`lemonway/deposit/wire-transfer-information`)
			.then((response) => {
				setIbanData(response.data)
			})
			.catch((err) => {
				if (err.response.data.code === 'ERR_ACCOUNT_STATUS_INVALID') {
					enqueueSnack({
						message: 'You need to validate your KYC',
						options: {
							variant: 'error',
						},
					})
					router.push('/user/settings/kyc')
				}
			})
	}, [])

	// RENDER
	// ----------------------------------------------------------------------------
	if (ibanData.iban) {
		return (
			<Box>
				<TextField
					inputRef={addressRef}
					id="iban"
					label="IBAN"
					variant="outlined"
					value={ibanData.iban}
					className={classes.buttonContainer}
					InputLabelProps={{
						shrink: true,
					}}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<CopyToClipboard text={ibanData.iban}>
									<IconButton aria-label="copy address" edge="end">
										<LaunchIcon />
									</IconButton>
								</CopyToClipboard>
							</InputAdornment>
						),
					}}
					fullWidth
					disabled
				/>
				<TextField
					id="bic"
					label="BIC"
					variant="outlined"
					value={ibanData.bic ? ibanData.bic : ibanData.swift}
					className={classes.buttonContainer}
					InputLabelProps={{
						shrink: true,
					}}
					fullWidth
					disabled
				/>
				<TextField
					id="bankName"
					label="Bank name"
					variant="outlined"
					value={ibanData.holder}
					className={classes.buttonContainer}
					InputLabelProps={{
						shrink: true,
					}}
					fullWidth
					disabled
				/>
			</Box>
		)
	} else return <CircularProgress color="inherit" size={20} />
}

/*
|--------------------------------------------------------------------------
| EXPORT
|--------------------------------------------------------------------------
*/
export { SAMDepositLemonwayBank }
