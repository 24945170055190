import * as React from 'react'
import { StepConnector, StepIconProps, Theme, withStyles } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import { Check } from '@material-ui/icons'

export const QontoConnector = withStyles((theme) => ({
	alternativeLabel: {
		top: 10,
		left: 'calc(-50% + 16px)',
		right: 'calc(50% + 16px)',
	},
	active: {
		'& $line': {
			borderColor: theme.palette.primary.main,
		},
	},
	completed: {
		'& $line': {
			borderColor: theme.palette.primary.main,
		},
	},
	line: {
		borderColor: '#eaeaf0',
		borderTopWidth: 3,
		borderRadius: 1,
	},
}))(StepConnector)

const useQontoStepIconStyles = makeStyles((theme: Theme) => ({
	root: {
		color: '#eaeaf0',
		display: 'flex',
		height: 22,
		alignItems: 'center',
	},
	active: {
		color: theme.palette.primary.main,
	},
	circle: {
		width: 8,
		height: 8,
		borderRadius: '50%',
		backgroundColor: 'currentColor',
	},
	completed: {
		color: theme.palette.primary.main,
		zIndex: 1,
		fontSize: 18,
	},
}))

export function QontoStepIcon(props: StepIconProps) {
	const classes = useQontoStepIconStyles()
	const { active, completed } = props

	return (
		<div
			className={clsx(classes.root, {
				[classes.active]: active,
			})}
		>
			{completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
		</div>
	)
}
